import { forEach } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

// pass an optional defaults object to the hook

interface TableParams {
  defaultSortBy?: string
  defaultSize?: number
  defaultPage?: number
  // extraParams?: { [key: string]: string }
  // onlyExtraParams?: boolean
}

export const useTableParams = (params?: TableParams) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(
    Number(searchParams.get('page')) || params?.defaultPage || 1,
  )
  const [size, setSize] = useState(
    Number(searchParams.get('size')) || params?.defaultSize || 10,
  )
  const [sortBy, setSortBy] = useState<string>(
    searchParams.get('sortBy') || params?.defaultSortBy || 'updatedAt',
  )
  //
  // const readExtraParams = () => {
  //   const returnParams: { [key: string]: string } = {}
  //   forEach(params?.extraParams, (_, val) => {
  //     if (searchParams.get(val)) {
  //       returnParams[val] = searchParams.get(val) || ''
  //     }
  //   })
  //   return returnParams
  // }
  //
  // const [extraParams, setExtraParams] = useState<{ [key: string]: string }>(
  //   params?.extraParams ? readExtraParams() : {},
  // )

  const [totalElements, setTotalElemenets] = useState<number>(10)

  useEffect(() => {
    // if (params?.onlyExtraParams) {
    //   setSearchParams({
    //     ...extraParams,
    //   })
    // } else {
      setSearchParams({
        page: page.toString(),
        size: size.toString(),
        sortBy,
        // ...extraParams,
      })
    // }
  }, [page, sortBy, size, /*extraParams, params?.onlyExtraParams*/])

  return {
    page: page - 1,
    setPage,
    size,
    setSize,
    sortBy,
    setSortBy,
    totalElements,
    setTotalElements: setTotalElemenets,
    // extraParams,
    // setExtraParams,
  }
}
