import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { Button } from "@mui/material";
import {getUserId, impersonate} from "../../services/userService";

export const ImpersonatePage: React.FC = (): JSX.Element => {
    const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const adminEmail = formData.get('adminEmail') as string;
        const targetEmail = formData.get('targetEmail') as string;

        // Custom logic here
        console.log('Admin Email:', adminEmail);
        console.log('Target Email:', targetEmail);

        try {
            const adminId = (await getUserId(adminEmail)).data;
            const targetId = (await getUserId(targetEmail)).data;

            if (adminId && targetId) {
                // Trigger another method call here
                console.log('Admin ID:', adminId);
                console.log('Target ID:', targetId);
                // Add your method call here
                impersonate({ admId: adminId, toImpId:targetId, enabled:true});
            } else {
                impersonate({ admId: '', toImpId:'', enabled:false});
                alert("Email not correct!")
            }
        } catch (error) {
            console.error('Error fetching user IDs:', error);
        }
    }, []);

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                <h1>Impersonate</h1>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Form style={{ maxWidth: 300 }} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formAdminEmail">
                            <Form.Label>Admin User Email</Form.Label>
                            <Form.Control name="adminEmail" type="text" placeholder="Enter Admin User email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formTargetEmail">
                            <Form.Label>Target User Email</Form.Label>
                            <Form.Control name="targetEmail" type="text" placeholder="Enter Target User email" />
                        </Form.Group>
                        <Button type="submit">
                            Impersonate
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    );
};