import {Pagination, PaginationParams, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IInstitution, IBook, INameId} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllInstitutions = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<IInstitution>>('/api/dashboard/institutions', {
        params,
    })

export const createInstitution = (data: INameId) =>
    http.post<IInstitution>('/api/dashboard/institutions', data)

export const updateInstitution  = (id: UUID, data: INameId) =>
    http.put<IInstitution>(`/api/dashboard/institutions/${id}`,  data )

export const deleteInstitution  = (id: UUID) =>
    http.delete(`/api/dashboard/institutions/${id}`, {})