import { FC } from 'react'
import { Image } from 'react-bootstrap'

import FormGroup, { IFormGroupProps } from './FormGroup'

type Props = {
  control: JSX.Element
  isUploading: boolean
  url?: string
  onRemove?: () => void
  label?: string
  previewLabel?: string
  imageStyle?: React.CSSProperties
  width?: number
  height?: number
  imageName?: string
  direction?: 'row' | 'column'
  disabled?: boolean
  formGroupProps?: Omit<IFormGroupProps, 'children'>
}

export const FormUploadPreview: FC<Props> = ({
  control,
  isUploading = false,
  url,
  onRemove,
  previewLabel,
  label,
  imageStyle,
  width,
  height,
  imageName,
  disabled,
  direction,
  formGroupProps,
}) => {
  if (url) {
    return (
      <>
        <FormGroup
          label={previewLabel}
          direction={direction}
          {...formGroupProps}
        >
          <div
            className=" w-100"
            style={{
              border: 'solid 1px #CED4DA',
              background: disabled ? '#CED4DA' : '#F5F5F5',
            }}
          >
            <div className="d-flex align-items-center justify-content-between gap-3 p-2">
              <Image
                src={url}
                style={{
                  width: width ? `${width}px` : '48px',
                  height: height ? `${height}px` : '48px',
                  objectFit: 'cover',
                  ...imageStyle,
                }}
              />
              {isUploading ? (
                <span
                  style={{
                    width: '100%',
                    color: '#6F42C1',
                    fontStyle: 'normal',
                  }}
                >
                  UPLOADING ...
                </span>
              ) : (
                <span
                  style={{
                    width: '100%',
                    color: '#6F42C1',
                    fontStyle: 'normal',
                  }}
                >
                  {imageName}
                </span>
              )}
              <i
                className="bi bi-trash me-2 d-flex"
                style={{
                  cursor: disabled ? 'default' : 'pointer',
                  color: disabled ? '#6C757D' : '#000',
                }}
                onClick={disabled ? undefined : onRemove}
              />
            </div>
          </div>
        </FormGroup>
      </>
    )
  } else {
    return (
      <FormGroup label={label} direction={direction} {...formGroupProps}>
        {control}
      </FormGroup>
    )
  }
}
