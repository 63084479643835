import {Pagination, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IAgeCategory, IInternalAgeGroup} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllInternalAgeGroups = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<IInternalAgeGroup>>('/api/dashboard/internal-age-group', {
        params,
    })

export const createInternalAgeGroup = (data: IAgeCategory) =>
    http.post<IInternalAgeGroup>('/api/dashboard/internal-age-group', data)

export const updateInternalAgeGroup = (id: UUID, data: IAgeCategory) =>
    http.put<IInternalAgeGroup>(`/api/dashboard/internal-age-group/${id}`,  data )

export const deleteInternalAgeGroup  = (id: UUID) =>
    http.delete(`/api/dashboard/internal-age-group/${id}`, {})