'use client';
import {BookListPage} from "./pages/books/BookListPage";
import React from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import MainLayout from "./core/components/layout/MainLayout";
import {routes} from "./routes";
import {CssBaseline} from "@mui/material";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import './App.css';

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  //
  //   const AppRouter: React.FC = () => (
  //     <Routes>
  //         <Route path="/book" >
  //             <Route index element={<CategoryListPage />} />
  //         </Route>
  //     </Routes>
  // )
    root.render(
        <React.StrictMode>
            <CssBaseline />
            <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainLayout />}>
                        {routes}
                    </Route>
                </Routes>
            </BrowserRouter>
            </Provider>
        </React.StrictMode>
    )


