import React from 'react';

type Props = {};

const InstitutionsIndex = (props: Props) => {
  return (
    <div>InstitutionsIndex</div>
  );
};

export default InstitutionsIndex;