'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useState} from "react";
import {IBookInPlanGroup, IBookInstance, IReadingPlanTemplate} from "../../core/interfaces";
import {createBookInstances, deleteBookInstance, getAllBooksInstances, updateBookInstance} from "../../services/books";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Button} from "@mui/material";
import {Pagination} from "../../core/components/Pagination";
import {Link, useParams} from "react-router-dom";
import {ArrowLeft} from "@mui/icons-material";
import {BookInstanceModal} from "./BookInstanceModal";
import {getAvailableBooksInTemplate, getPlan} from "../../services/templatePlans";
import {PlanGroupDetails} from "./PlanGroupDetails";

export const PlanGroupsListPage: React.FC = (): JSX.Element => {

    const {planId} = useParams()

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [plan, setPlan] = useState<IReadingPlanTemplate>()
    const [avBooks, setAvBooks] = useState<IBookInPlanGroup[]>([])

    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const response = await getPlan(planId)
            setPlan(response.data)

            const [books] = await Promise.all(
                [getAvailableBooksInTemplate(planId)])
            setAvBooks(books.data)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    },[])

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            {loading && <Loader />}

            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >

                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    <h2>
                        <Link to={`/books/plans`}>
                            <ArrowLeft style={{backgroundColor: 'black', color: 'white', padding: '0px', width: '20px', height: '20px', textAlign: 'center', lineHeight: '1.1', borderRadius: '10px', marginRight: '10px'}}/>
                        </Link>
                        Template Plan {plan?.label}</h2>

                </div>
                <div className="overflow-x-auto">

                    {plan?.readingPlanTemplateGroups.map(planGroup => <PlanGroupDetails group={planGroup} avBooks={avBooks} getData={getData}/>)}
                </div>
            </div>

        </>
    )
}