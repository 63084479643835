import { Button, Modal } from 'react-bootstrap'

export interface DeleteConfirmationModalProps {
  showDeleteConfirmationModal: boolean
  setDeleteConfirmationModal: (show: boolean) => void
  deleteFunc: () => void
  cancelText?: boolean
  deleteTitle?: string
  onClose?: () => void
}

export const DeleteConfirmationModal = (
  props: DeleteConfirmationModalProps,
): JSX.Element => {
  const {
    showDeleteConfirmationModal,
    setDeleteConfirmationModal,
    deleteFunc,
    deleteTitle,
    onClose,
    cancelText = false,
  } = props

  const handleClose = () => {
    setDeleteConfirmationModal(false)
    onClose?.()
  }

  const handleDelete = () => {
    deleteFunc()
    handleClose()
  }

  return (
    <Modal
      show={showDeleteConfirmationModal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontSize: '24px',
            fontFamily: 'Helvetica Neue',
            fontWeight: '700',
            lineHeight: '100%',
            fontStyle: 'normal',
          }}
        >
          <span className="" style={{}}>
            {`${cancelText ? 'Cancel' : 'Delete'} ${deleteTitle}`}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="mb-2">
          Are you sure you want to {cancelText ? 'cancel' : 'delete'}?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          className="btn btn-secondary"
          data-dismiss="modal"
          type="button"
          style={{ color: '#FFF' }}
        >
          No
        </Button>
        <Button
          type="button"
          className="btn btn-danger"
          onClick={handleDelete}
          style={{ color: '#FFF' }}
        >
          Yes{!cancelText && ', Delete'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
