import {BookFilterSearchPaginationParams, Pagination, PaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IBook, IBookInstance, Image} from "../core/interfaces";
import {UUID} from "node:crypto";
import {BookPayload} from "../pages/books/BookModal";
import {BookInstanceModel} from "../pages/books/BookInstanceModal";

export const getAllBooks = (params?: BookFilterSearchPaginationParams) =>
    http.get<Pagination<IBook>>('/api/dashboard/books', {
        params,
    })

export const getAllBooksInstances = (id: string, params?: PaginationParams) =>
    http.get<Pagination<IBookInstance>>(`/api/dashboard/books/${id}/instances`,{
        params,
    });
export const getBookCatalogPrice = (id: string, params?: PaginationParams) =>
    http.get<number>(`/api/dashboard/books/${id}/catalog-price`);

export const createBookInstances = (id: string, data: BookInstanceModel) =>
    http.post(`/api/dashboard/books/${id}/instances`,  data)

export const updateBookInstance = (bookId: string, id: UUID, data: BookInstanceModel) =>
    http.put<IBookInstance>(`/api/dashboard/books/${bookId}/instances/${id}`,  data)

export const deleteBookInstance = (id: UUID) =>
    http.delete(`/api/dashboard/books/instances/${id}`, {})

export const createBook = (data: BookPayload) =>
http.post<IBook>('/api/dashboard/books', data)

export const updateBook = (id: UUID, data: BookPayload) =>
    http.put<IBook>(`/api/dashboard/books/${id}`,  data);

export const deleteBook = (id: UUID) =>
    http.delete(`/api/dashboard/books/${id}`, {})

export const uploadPhoto = ( data: BookInstanceModel) =>
    http.post<Image>(``,  data)