import { yupResolver } from '@hookform/resolvers/yup'
import {useCallback, useEffect, useState} from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { DeleteConfirmationModal } from '../../core/components/DeleteConfirmationModal'
import { MessageBox } from '../../core/components/MessageBox'
import {
  IAddress,
  IInstitution,
  INameId,
  IReadingPlanRequest,
  IReadingPlanSummary,
  IReadingPlanTemplate
} from "../../core/interfaces";
import {UUID} from "node:crypto";
import {Pagination, SimpleSearchPaginationParams} from "../../core/services/utils";
import axios, {AxiosResponse} from 'axios';
import {date} from "yup";
import {getAllPlans} from "../../services/templatePlans";
import {map} from "lodash-es";

export interface ReadingPlanModalProps {
  currentGroupId?: string
  planId: UUID,
  showCreateModal: boolean
  setShowCreateModal: (show: boolean) => void
  setEditMode: (editMode: boolean) => void
  editMode: boolean
  page: number
  size: number
  setTotalElements: (elements: number) => void
  setAllList: (plans: IReadingPlanSummary[]) => void
  readingPlanRequest?: IReadingPlanRequest
  createCall: (groupId: string, data: IReadingPlanRequest) => Promise<AxiosResponse<IReadingPlanSummary>>
  deleteCall: (id: UUID) => Promise<AxiosResponse<any>>
  updateCall: (grupId: string, planId: string) =>  Promise<AxiosResponse<IReadingPlanSummary>>
  getAllCall: (id: string, params?: SimpleSearchPaginationParams) =>  Promise<AxiosResponse<Pagination<IReadingPlanSummary>>>
}

export interface IReadingPlanRequestModel {
  templateId?: string
  weekFrequency?: number
  numberOfSwitches?: number
  createSchedule?: boolean
  assignUsers?: boolean
  startDateTimestamp?: Date
}

export const ReadingPlanModal = (
  props: ReadingPlanModalProps,
): JSX.Element => {
  const {
    currentGroupId,
    planId,
    readingPlanRequest,
    setShowCreateModal,
    setAllList,
    showCreateModal,
    setEditMode,
    editMode,
    page,
    size,
    setTotalElements,
    createCall,
    deleteCall,
    updateCall,
    getAllCall

  } = props

  const [showDeleteConfirmationModal, setDeleteConfirmationModal] =
    useState<boolean>(false)

  const [isError, setIsError] = useState<boolean>(false)
  const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [plans, setPlans] = useState<IReadingPlanTemplate[]>([])

  const getData = useCallback(async () => {
    const params: SimpleSearchPaginationParams = {
      page,
      size,
    };
    params.page=0;
    params.size=50;
    const response = getAllPlans(params)
    response.then(value => setPlans(value.data.content))
  }, [page, size])

  useEffect(() => {
    getData();

    if (editMode) {

    }

  }, [readingPlanRequest, editMode])

  const schema = yup.object().shape({
    templateId: yup.string().nullable(),
    weekFrequency: yup.number(),
    numberOfSwitches: yup.number(),
    createSchedule: yup.boolean(),
    assignUsers:yup.boolean(),
    startDateTimestamp:yup.date().nullable()
  })

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    reset,
    setValue,
  } = useForm<IReadingPlanRequestModel>({
    mode: 'onChange',
    resolver: yupResolver<IReadingPlanRequestModel>(schema),
    defaultValues: {
      templateId: null,
      weekFrequency: 4,
      numberOfSwitches: 12,
      createSchedule: false,
      assignUsers:false,
      startDateTimestamp:null,
    },
  })

  const handleDelete = () => {
    // prettier-ignore
    (async () => {
      try {
        if (planId) {
          const { status } = await deleteCall(planId)
          const response = await getAllCall(currentGroupId, {
            page: page - 1,
            size,
          })
          setTotalElements(response.data.totalElements)
          setAllList(response.data.content)
          if (status === 204 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Delete Successful')
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    })()
    handleClose()
  }

  const handleClose = () => {
    setShowCreateModal(false)
    setEditMode(false)
    reset()
  }

  const submit = (values: IReadingPlanRequestModel) => {
    // prettier-ignore
    (async () => {
      try {

        const data: IReadingPlanRequest = {
          templateId: values.templateId,
          weekFrequency: values.weekFrequency,
          numberOfSwitches: values.numberOfSwitches,
          assignUsers: values.assignUsers,
          startDateTimestamp: values.startDateTimestamp.getTime(),
          createSchedule: values.createSchedule
        }
        if (planId) {
          const { status } = await updateCall(currentGroupId, planId)
          const response = await getAllCall(currentGroupId, {
            page: page - 1,
            size,
          })
          setAllList(response.data.content)
          if (status === 200 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Update Successful')
          }
        } else {
          const { status } = await createCall(currentGroupId, data)
          const response = await getAllCall(currentGroupId, {
            page: page - 1,
            size,
          })
          setTotalElements(response.data.totalElements)
          setAllList(response.data.content)
          if (status === 200 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Create Successful')
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    })()
    handleClose()
  }

  return (
    <>
      <Modal
        show={showCreateModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode
              ? `Edit`
              : `Add New`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="iname-modal-form" onSubmit={handleSubmit(submit)}>
            <Form.Group className="d-flex flex-column align-items-start gap-3">
              <Form.Label
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Helvetica Neue',
                    fontWeight: '700',
                    lineHeight: '150%',
                    fontStyle: 'normal',
                    width: '132px',
                  }}
                  className="d-flex flex-column mb-0"
              >
                Group
              </Form.Label>
              <Form.Select className="d-flex flex-column mb-0"
                           isInvalid={!!errors?.templateId?.message}
                           onChange={(e) => {
                             const val = e.target.value
                             setValue('templateId', val, {shouldDirty: true})
                           }}
              >
                <option value={'1'}
                        selected={editMode == false }>
                  No Group
                </option>
                {map(plans, (group) => (
                    <option id={group.id} key={group.id} value={group.id}
                            selected={editMode == true && group.id == planId}>
                      {group.label}
                    </option>
                ))}
              </Form.Select>

              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                <Form.Label
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Helvetica Neue',
                    fontWeight: '700',
                    lineHeight: '150%',
                    fontStyle: 'normal',
                    width: '132px',
                  }}
                  className="d-flex flex-column mb-0"
                >
                  Week Frequency
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors?.weekFrequency?.message}
                  {...register('weekFrequency')}
                  type="number"
                  placeholder="Enter Week Frequency"
                />

                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Number Of Switches
                </Form.Label>
                <Form.Control
                    disabled={editMode}
                    isInvalid={!!errors?.numberOfSwitches?.message}
                    {...register('numberOfSwitches')}
                    type="text"
                    placeholder="Enter Number Of Switches"
                />
              </Form.Group>
              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Generate schedule?
                </Form.Label>
                <input
                    {...register('createSchedule')}
                    type="checkbox"
                />

                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Auto assign users?
                </Form.Label>
                <input
                    type="checkbox"
                    {...register('assignUsers')}
                />
              </Form.Group>
            </Form.Group>


              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Start time
                </Form.Label>
                <Form.Control
                    isInvalid={!!errors?.startDateTimestamp?.message}
                    {...register('startDateTimestamp')}
                    type="date"
                    placeholder="Start time"
                />
              </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          {editMode && (
            <Button
              onClick={handleDelete}
              style={{ color: '#FFFFFF' }}
              className="btn btn-danger me-auto "
            >
              Delete
            </Button>
          )}
          <Button
            onClick={handleClose}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            form="iname-modal-form"
            type="submit"
            disabled={Object.keys(dirtyFields).length === 0}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteConfirmationModal
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        setDeleteConfirmationModal={setDeleteConfirmationModal}
        deleteTitle={'Delete'}
        deleteFunc={handleDelete}
      />
      <MessageBox
        message={message}
        setShowMessageBox={setShowMessageBox}
        showMessageBox={showMessageBox}
        isError={isError}
      />
    </>
  )
}
