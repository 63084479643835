import {yupResolver} from '@hookform/resolvers/yup'
import {useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import * as yup from 'yup'

import {DeleteConfirmationModal} from '../../core/components/DeleteConfirmationModal'
import {MessageBox} from '../../core/components/MessageBox'
import {IInternalAgeGroup} from "../../core/interfaces";
import {UUID} from "node:crypto";
import {Pagination, SimpleSearchPaginationParams} from "../../core/services/utils";
import {AxiosResponse} from 'axios';

export interface InternalAgeGroupModalProps {
  showCreateModal: boolean
  setShowCreateModal: (show: boolean) => void
  setEditMode: (editMode: boolean) => void
  editMode: boolean
  page: number
  size: number
  setTotalElements: (elements: number) => void
  setAllList: (categories: IInternalAgeGroup[]) => void
  internalAgeGroupEntity?: IInternalAgeGroup
  createCall: (data: IInternalAgeGroup) => Promise<AxiosResponse<IInternalAgeGroup>>
  deleteCall: (id: UUID) => Promise<AxiosResponse<any>>
  updateCall: (id: UUID, data: IInternalAgeGroup) =>  Promise<AxiosResponse<IInternalAgeGroup>>
  getAllCall: (params?: SimpleSearchPaginationParams) =>  Promise<AxiosResponse<Pagination<IInternalAgeGroup>>>
}
export interface InternalAgeGroupModeledId {
  name?: string
  startAge?: number
  endAge?: number
}

export const InternalAgeGroupModal = (
  props: InternalAgeGroupModalProps,
): JSX.Element => {
  const {
    internalAgeGroupEntity,
    setShowCreateModal,
    setAllList,
    showCreateModal,
    setEditMode,
    editMode,
    page,
    size,
    setTotalElements,
    createCall,
    deleteCall,
    updateCall,
    getAllCall

  } = props

  const [showDeleteConfirmationModal, setDeleteConfirmationModal] =
    useState<boolean>(false)

  const [isError, setIsError] = useState<boolean>(false)
  const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    if (editMode) {
      setValue('name', internalAgeGroupEntity?.name)
      setValue('startAge', internalAgeGroupEntity?.startAge)
      setValue('endAge', internalAgeGroupEntity?.endAge)
    }
  }, [internalAgeGroupEntity, editMode])

  const schema = yup.object().shape({
    name: yup.string(),
    startAge: yup.number(),
    endAge: yup.number(),
  })

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    reset,
    setValue,
  } = useForm<InternalAgeGroupModeledId>({
    mode: 'onChange',
    resolver: yupResolver<InternalAgeGroupModeledId>(schema),
    defaultValues: {
      name: '',
      startAge: 0,
      endAge: 0
    },
  })

  const handleDelete = () => {
    // prettier-ignore
    (async () => {
      try {
        if (internalAgeGroupEntity) {
          const { status } = await deleteCall(internalAgeGroupEntity?.id)
          const response = await getAllCall({
            page: page - 1,
            size,
          })
          setTotalElements(response.data.totalElements)
          setAllList(response.data.content)
          if (status === 204 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Delete Successful')
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    })()
    handleClose()
  }

  const handleClose = () => {
    setShowCreateModal(false)
    setEditMode(false)
    reset()
  }

  const submit = (values: InternalAgeGroupModeledId) => {
    // prettier-ignore
    (async () => {
      try {
        const data: IInternalAgeGroup = {
          name: values.name,
          startAge: values.startAge,
          endAge: values.endAge
        }
        if (internalAgeGroupEntity) {
          const { status } = await updateCall(internalAgeGroupEntity.id, data)
          const response = await getAllCall({
            page: page - 1,
            size,
          })
          setAllList(response.data.content)
          if (status === 200 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Update Successful')
          }
        } else {
          const { status } = await createCall(data)
          const response = await getAllCall({
            page: page - 1,
            size,
          })
          setTotalElements(response.data.totalElements)
          setAllList(response.data.content)
          if (status === 200 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Create Successful')
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    })()
    handleClose()
  }

  return (
    <>
      <Modal
        show={showCreateModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode
              ? `Edit ${internalAgeGroupEntity?.name}`
              : `Add New`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="age-category-form" onSubmit={handleSubmit(submit)}>
            <Form.Group className="d-flex flex-column align-items-start gap-3">
              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
              <Form.Label
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Helvetica Neue',
                    fontWeight: '700',
                    lineHeight: '150%',
                    fontStyle: 'normal',
                    width: '132px',
                  }}
                  className="d-flex flex-column mb-0"
              >
                Name
              </Form.Label>
              <Form.Control
                  isInvalid={!!errors?.name?.message}
                  {...register('name')}
                  type="text"
                  placeholder="Enter Name"
              />
            </Form.Group>

              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Start Age
                </Form.Label>
                <Form.Control
                    isInvalid={!!errors?.startAge?.message}
                    {...register('startAge')}
                    type="number"
                    placeholder="Enter Start Age"
                />

                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  End Age
                </Form.Label>
                <Form.Control
                    isInvalid={!!errors?.endAge?.message}
                    {...register('endAge')}
                    type="number"
                    placeholder="Enter End Age"
                />
              </Form.Group>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {editMode && (
            <Button
              onClick={handleDelete}
              style={{ color: '#FFFFFF' }}
              className="btn btn-danger me-auto "
            >
              Delete
            </Button>
          )}
          <Button
            onClick={handleClose}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            form="age-category-form"
            type="submit"
            disabled={Object.keys(dirtyFields).length === 0}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteConfirmationModal
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        setDeleteConfirmationModal={setDeleteConfirmationModal}
        deleteTitle={'Delete'}
        deleteFunc={handleDelete}
      />
      <MessageBox
        message={message}
        setShowMessageBox={setShowMessageBox}
        showMessageBox={showMessageBox}
        isError={isError}
      />
    </>
  )
}
