'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {INameId} from "../../core/interfaces";
import {createLanguage, deleteLanguage, getAllLanguages, updateLanguage} from "../../services/langauges";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Pagination} from "../../core/components/Pagination";
import {Button} from "@mui/material";
import {SimpleSearchPaginationParams} from "../../core/services/utils";
import {INameModal} from "./INameModal";

export const LanguagesListPage: React.FC = (): JSX.Element => {
    const {
        page,
        setPage,
        size,
        setSize,
        totalElements,
        setTotalElements,
        sortBy,
        setSortBy,

    } = useTableParams({
        defaultSortBy: 'updatedAt',
    }
    )

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [languages, setLanguages] = useState<INameId[]>([])
    const [isCheck, setIsCheck] = useState<string[]>([])
    const searchParams = useRef<{ [key: string]: string }>({})
    const timer = useRef<NodeJS.Timeout | null>(null)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentLanguage, setCurrentLanguage] = useState<INameId>()
    const [showCreateLanguageModal, setShowCreateLanguageModal] = useState<boolean>(false)
    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const params: SimpleSearchPaginationParams = {
                page,
                size,
                sortBy,
            };
            params.text = searchParams.current.name
            const response = await getAllLanguages(params)
            setTotalElements(response.data.totalElements)
            setLanguages(response.data.content)
            setPage(page + 1)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [page, size, sortBy])

    useEffect(() => {
        getData()
    }, [getData, page, size, sortBy])

    const handleSortBy = (sort: string) => {
        if (sort === sortBy) {
            setSortBy('updatedAt')
        } else {
            setSortBy(sort)
        }
    }

    const pageChange = async (newPage: number) => {
        setPage(newPage)
    }

    const handleSearchParams = useCallback(
        async (col: IColumn, q: string) => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            searchParams.current = {
                ...searchParams.current,
                [col.accessor]: q,
            }

            const newTimer = setTimeout(async () => {
                setLoading(true)
                try {
                    const params: SimpleSearchPaginationParams = {
                        page: 0,
                        size,
                        sortBy,
                    }

                    params.text = searchParams.current.name
                    const { data: res, status } = await getAllLanguages(params)
                    if (status === 200) {
                        setLanguages(res.content)
                        setTotalElements(res.totalElements)
                        setSize(res.size)
                        setPage(res.pageNumber + 1)
                    }
                } catch (err) {
                    console.log(err)
                } finally {
                    setLoading(false)
                }
            }, 2000)
            timer.current = newTimer
        },
        [page, size, sortBy],
    )

    const columns: IColumn[] = [
        {
            title: 'ID',
            accessor: 'id',
            sortable: true,
        },
        {
            title: 'Name',
            accessor: 'name',
            sortable: true,
            searchable: true
        },
        {
            title: 'Edit',
            accessor: '',
            render: (row) => (
                <div
                    style={{ cursor: 'pointer' }}
                    className="view"
                    onClick={() => {
                        setEditMode(true)
                        setCurrentLanguage(row)
                        setShowCreateLanguageModal(true)
                    }}
                >
                    Edit
                </div>
            ),
        }
    ]

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader />}
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    <h2>Languages</h2>
                    <div className="d-flex gap-2">
                        <Button variant="contained" size="small" onClick={() => {
                            setEditMode(false)
                            setShowCreateLanguageModal(true)
                            setCurrentLanguage(undefined)
                        }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="bi bi-arrow-right-square me-2 d-flex" />
                                <span>Add new Language</span>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="overflow-x-auto">
                <Table
                    handleSearch={handleSearchParams}
                    isCheck={isCheck}
                    columns={columns}
                    handleSortBy={handleSortBy}
                    data={languages}
                />
                </div>
                <Pagination
                    size={size}
                    totalElements={totalElements}
                    setSize={setSize}
                    page={page + 1}
                    changePage={pageChange}
                />
                <INameModal
                    iNameEntity={currentLanguage}
                    setEditMode={setEditMode}
                    setShowCreateModal={setShowCreateLanguageModal}
                    setAllList={setLanguages}
                    showCreateModal={showCreateLanguageModal}
                    editMode={editMode}
                    page={page + 1}
                    size={size}
                    setTotalElements={setTotalElements}
                    createCall={createLanguage}
                    deleteCall={deleteLanguage}
                    updateCall={updateLanguage}
                    getAllCall={getAllLanguages}
                />

            </div>
        </>
    )
}