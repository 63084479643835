import classNames from 'classnames'
import {FC, useId} from 'react'
import {Form} from 'react-bootstrap'


export interface IFormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: JSX.Element
  wrapperClassName?: string
  labelWrapperClassName?: string
  inputWrapperClassName?: string
  labelClassName?: string
  label?: string
  labelProps?: React.HTMLAttributes<HTMLLabelElement>
  tooltip?: string
  labelStyle?: React.CSSProperties
  direction?: 'row' | 'column'
  justifyContent?: 'between' | 'center' | 'start' | 'end' | 'around'
  error?: string
}

const FormGroup: FC<IFormGroupProps> = ({
  wrapperClassName,
  labelClassName,
  label,
  labelProps,
  children,
  tooltip,
  labelWrapperClassName,
  inputWrapperClassName,
  labelStyle,
  direction = 'column',
  justifyContent = 'between',
  error,
  ...props
}) => {
  const toolTipId = useId()
  return (
    <Form.Group
      className={classNames(
        'mb-1 d-flex gap-1 w-100',
        wrapperClassName,
        direction === 'row' ? 'flex-row' : 'flex-column',
      )}
      {...props}
    >
      <div
        className={classNames(
          `d-flex justify-content-${justifyContent}`,
          direction === 'row' ? 'w-auto' : 'w-100',
          labelWrapperClassName,
        )}
        style={labelStyle}
      >
        <Form.Label
          className={classNames('d-flex flex-column mb-0', labelClassName)}
          {...labelProps}
        >
          {label}
        </Form.Label>
      </div>

      <div
        className={classNames(
          'd-flex align-items-start gap-2 w-100',
          inputWrapperClassName,
        )}
      >
        {children}
      </div>
      {error && <small className="text-danger">{error}</small>}
    </Form.Group>
  )
}

export default FormGroup
