import classNames from 'classnames'
import { FC } from 'react'
import Spinner from 'react-bootstrap/Spinner'

const Loader: FC<{ position?: 'absolute' }> = ({ position }) => {
  return (
    <div
      className={classNames(
        'fullscreen-loader',
        position === 'absolute' && 'position-absolute',
      )}
    >
      <div className="loading-card">
        <div className="loading-title">
          <p className="text-primary">Loading, please wait ….</p>
        </div>
        <div className="loading-body text-primary">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    </div>
  )
}

export default Loader
