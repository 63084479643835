import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { DeleteConfirmationModal } from '../../core/components/DeleteConfirmationModal'
import { MessageBox } from '../../core/components/MessageBox'
import {INameId} from "../../core/interfaces";
import {UUID} from "node:crypto";
import {Pagination, SimpleSearchPaginationParams} from "../../core/services/utils";
import axios, {AxiosResponse} from 'axios';

export interface INameModalProps {
  showCreateModal: boolean
  setShowCreateModal: (show: boolean) => void
  setEditMode: (editMode: boolean) => void
  editMode: boolean
  page: number
  size: number
  setTotalElements: (elements: number) => void
  setAllList: (categories: INameId[]) => void
  iNameEntity?: INameId
  createCall: (data: INameId) => Promise<AxiosResponse<INameId>>
  deleteCall: (id: UUID) => Promise<AxiosResponse<any>>
  updateCall: (id: UUID, data: INameId) =>  Promise<AxiosResponse<INameId>>
  getAllCall: (params?: SimpleSearchPaginationParams) =>  Promise<AxiosResponse<Pagination<INameId>>>
}
export interface INameModeledId {
  name?: string
}

export const INameModal = (
  props: INameModalProps,
): JSX.Element => {
  const {
    iNameEntity,
    setShowCreateModal,
    setAllList,
    showCreateModal,
    setEditMode,
    editMode,
    page,
    size,
    setTotalElements,
    createCall,
    deleteCall,
    updateCall,
    getAllCall

  } = props

  const [showDeleteConfirmationModal, setDeleteConfirmationModal] =
    useState<boolean>(false)

  const [isError, setIsError] = useState<boolean>(false)
  const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    if (editMode) {
      setValue('name', iNameEntity?.name)
    }
  }, [iNameEntity, editMode])

  const schema = yup.object().shape({

    name: yup.string(),
  })

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    reset,
    setValue,
  } = useForm<INameModeledId>({
    mode: 'onChange',
    resolver: yupResolver<INameModeledId>(schema),
    defaultValues: {
      name: ''
    },
  })

  const handleDelete = () => {
    // prettier-ignore
    (async () => {
      try {
        if (iNameEntity) {
          const { status } = await deleteCall(iNameEntity?.id)
          const response = await getAllCall({
            page: page - 1,
            size,
          })
          setTotalElements(response.data.totalElements)
          setAllList(response.data.content)
          if (status === 204 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Delete Successful')
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    })()
    handleClose()
  }

  const handleClose = () => {
    setShowCreateModal(false)
    setEditMode(false)
    reset()
  }

  const submit = (values: INameModeledId) => {
    // prettier-ignore
    (async () => {
      try {
        const data: INameId = {
          name: values.name
        }
        if (iNameEntity) {
          const { status } = await updateCall(iNameEntity.id, data)
          const response = await getAllCall({
            page: page - 1,
            size,
          })
          setAllList(response.data.content)
          if (status === 200 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Update Successful')
          }
        } else {
          const { status } = await createCall(data)
          const response = await getAllCall({
            page: page - 1,
            size,
          })
          setTotalElements(response.data.totalElements)
          setAllList(response.data.content)
          if (status === 200 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Create Successful')
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    })()
    handleClose()
  }

  return (
    <>
      <Modal
        show={showCreateModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode
              ? `Edit ${iNameEntity?.name}`
              : `Add New`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="iname-modal-form" onSubmit={handleSubmit(submit)}>
            <Form.Group className="d-flex flex-column align-items-start gap-3">
              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                <Form.Label
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Helvetica Neue',
                    fontWeight: '700',
                    lineHeight: '150%',
                    fontStyle: 'normal',
                    width: '132px',
                  }}
                  className="d-flex flex-column mb-0"
                >
                  Name
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors?.name?.message}
                  {...register('name')}
                  type="text"
                  placeholder="Enter Name"
                />
              </Form.Group>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {editMode && (
            <Button
              onClick={handleDelete}
              style={{ color: '#FFFFFF' }}
              className="btn btn-danger me-auto "
            >
              Delete
            </Button>
          )}
          <Button
            onClick={handleClose}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            form="iname-modal-form"
            type="submit"
            disabled={Object.keys(dirtyFields).length === 0}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteConfirmationModal
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        setDeleteConfirmationModal={setDeleteConfirmationModal}
        deleteTitle={'Delete'}
        deleteFunc={handleDelete}
      />
      <MessageBox
        message={message}
        setShowMessageBox={setShowMessageBox}
        showMessageBox={showMessageBox}
        isError={isError}
      />
    </>
  )
}
