import { Button, Modal } from 'react-bootstrap'

interface MessageBoxProps {
  isError: boolean
  message: string
  setShowMessageBox: (sM: boolean) => void
  showMessageBox: boolean
}

export const MessageBox = (props: MessageBoxProps): JSX.Element => {
  const { isError, message, showMessageBox, setShowMessageBox } = props

  const handleClose = () => {
    setShowMessageBox(false)
  }

  return (
    <Modal
      show={showMessageBox}
      onHide={handleClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ lineHeight: '24px', fontSize: '20px' }}>
          {isError ? (
            <h5 style={{ color: '#DC3545' }}>ERROR</h5>
          ) : (
            <h5 style={{ color: '#1AA179' }}>SUCCESS!</h5>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <span
            style={{
              fontSize: '16px',
              lineHeight: '24px',
              fontStyle: 'normal',
              fontWeight: '400',
            }}
          >
            {message}
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          className="btn btn-secondary"
          data-dismiss="modal"
          type="button"
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
