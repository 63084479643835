'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {IInstitutionGroupDetails, IReadingPlanSummary} from "../../core/interfaces";
import {getInstitutionGroup} from "../../services/institutionGroups";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Pagination} from "../../core/components/Pagination";
import {Button} from "@mui/material";
import {SimpleSearchPaginationParams} from "../../core/services/utils";
import {Link, useParams} from "react-router-dom";
import {ArrowLeft, CheckCircle} from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    activateReadingPlan,
    assignUsersPlan,
    createReadingPlan,
    deleteReadingPlan,
    generateSchedulePlan,
    getAllPlansForIGroup,
    updateReadingPlan
} from "../../services/readingPlans";
import {concat} from "lodash-es";
import {ReadingPlanModal, ReadingPlanModalProps} from "./ReadingPlanModal";
import {response} from "express";

export interface DateFromTimestampProps {
    date: number
}
export const DateFromTimestamp = ( props: DateFromTimestampProps): JSX.Element => {
    const {
        date
    } = props
    return (
        <span>
            {(new Date(date)).toDateString()}
        </span>
    );
}

export interface ActiveMarkerProps {
    active: boolean
}
export const ActiveMarker = ( props: ActiveMarkerProps): JSX.Element => {
    const {
        active
    } = props
    return (
        <>
            {
                active === true ?
                    <CheckCircleIcon/>
                :
                    <CancelIcon/>
            }
        </>
    );
}
export const ReadingPlansListPage: React.FC = (): JSX.Element => {
    const {
        page,
        setPage,
        size,
        setSize,
        totalElements,
        setTotalElements,
        sortBy,
        setSortBy,

    } = useTableParams({
        defaultSortBy: 'updatedAt',
    }
    )
    const {iGroupId} = useParams()
    const [iGroupDetails, setIGroupDetails] = useState<IInstitutionGroupDetails>()

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [readingPlans, setReadingPlans] = useState<IReadingPlanSummary[]>([])
    const [isCheck, setIsCheck] = useState<string[]>([])
    const searchParams = useRef<{ [key: string]: string }>({})
    const timer = useRef<NodeJS.Timeout | null>(null)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentPlan, setCurrentPlan] = useState<IReadingPlanSummary>()
    const [showCreatePlanModal, setShowCreatePlanModal] = useState<boolean>(false)
    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const params: SimpleSearchPaginationParams = {
                page,
                size,
                sortBy,
            };
            params.text = searchParams.current.name
            const response = await getAllPlansForIGroup(iGroupId, params)
            setTotalElements(response.data.totalElements)
            setReadingPlans(response.data.content)
            setPage(page + 1)
            const groupResponse = await getInstitutionGroup(iGroupId)
            setIGroupDetails(groupResponse.data)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [page, size, sortBy])

    useEffect(() => {
        getData()
    }, [getData, page, size, sortBy])

    const handleSortBy = (sort: string) => {
        if (sort === sortBy) {
            setSortBy('updatedAt')
        } else {
            setSortBy(sort)
        }
    }

    const pageChange = async (newPage: number) => {
        setPage(newPage)
    }

    const handleSearchParams = useCallback(
        async (col: IColumn, q: string) => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            searchParams.current = {
                ...searchParams.current,
                [col.accessor]: q,
            }

            const newTimer = setTimeout(async () => {
                setLoading(true)
                try {
                    const params: SimpleSearchPaginationParams = {
                        page: 0,
                        size,
                        sortBy,
                    }

                    params.text = searchParams.current.name
                    const { data: res, status } = await getAllPlansForIGroup(iGroupId, params)
                    if (status === 200) {
                        setReadingPlans(res.content)
                        setTotalElements(res.totalElements)
                        setSize(res.size)
                        setPage(res.pageNumber + 1)
                    }
                } catch (err) {
                    console.log(err)
                } finally {
                    setLoading(false)
                }
            }, 2000)
            timer.current = newTimer
        },
        [page, size, sortBy],
    )

    function assignUsers(id :string) {
        assignUsersPlan(id).then(value => window.location.reload());
    }

    function generateSchedule(id :string) {
        generateSchedulePlan(id).then(value => window.location.reload());
    }

    function activate(id) {
        activateReadingPlan(id).then(response => {
            if (!response.data.success) {
                alert(response.data.message);
            } else {
                window.location.reload();
            }
        })
    }

    const columns: IColumn[] = [
        {
            title: 'Strat date',
            accessor: '',
            render: (row: IReadingPlanSummary) => (
                <DateFromTimestamp date={row.startDate}/>
            )
        },
        {
            title: 'Week Frequency',
            accessor: 'weekFrequency',
            sortable: true,
        },
        {
            title: 'Switches',
            accessor: 'numberOfSwitches',
            sortable: true,
        },
        {
            title: 'Users',
            accessor: 'numberOfUsers',
            sortable: true,
        },
        {
            title: 'Scheduled?',
            accessor: 'scheduled',
            sortable: true,
            render: (row: IReadingPlanSummary) => (
                <ActiveMarker active={row.scheduled} />
            )
        },
        {
            title: 'Are users assigned?',
            accessor: 'assignedUsers',
            sortable: true,
            render: (row: IReadingPlanSummary) => (
                 <ActiveMarker active={row.assignedUsers} />
            )
        },
        {
            title: 'Missing books',
            accessor: '',
            render: (row: IReadingPlanSummary) => (
                <div>
                    {row.booksMissing.length}
                </div>
            ),
        },
        {
            title: 'Active',
            accessor: '',
            render: (row) => (
                <ActiveMarker active={row.active} />

            ),
        },
        {
            title: 'View',
            accessor: '',
            render: (row) => (
                <Link to={`/plans/${iGroupId}/${row.id}`}>
                    <Button className="btn btn-secondary">
                        View details
                    </Button>

                </Link>
            ),
        },
        {
            title: 'Generate Schedule',
            accessor: '',
            render: (row) => (
                <Button className="btn btn-secondary" onClick={() => generateSchedule(row.id)}>
                    Generate Schedule
                </Button>
            ),
        },
        {
            title: 'Assign users',
            accessor: '',
            render: (row) => (
                <Button className="btn btn-secondary" onClick={() => assignUsers(row.id)}>
                    Assign users
                </Button>
            ),
        },
        {
            title: 'Activate',
            accessor: '',
            render: (row) => (
                <Button className="btn btn-secondary" onClick={() => activate(row.id)}>
                    Activate
                </Button>
            ),
        }
    ]

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader />}
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    {iGroupDetails ?
                            <>
                                <Link to={`/institutions/${iGroupDetails.institutionId}/groups`}>
                                    <ArrowLeft style={{backgroundColor: 'black', color: 'white', padding: '0px', width: '20px', height: '20px', textAlign: 'center', lineHeight: '1.1', borderRadius: '10px', marginRight: '10px'}}/>
                                </Link>
                                <h2>Plans for group {concat(iGroupDetails.institutionName, " - ", iGroupDetails.name)}</h2>
                            </>
                            :
                            <></>
                    }
                    <div className="d-flex gap-2">
                        <Button variant="contained" size="small" onClick={() => {
                            setEditMode(false)
                            setShowCreatePlanModal(true)
                            setCurrentPlan(undefined)
                        }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="bi bi-arrow-right-square me-2 d-flex" />
                                <span>Add new Plan</span>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="overflow-x-auto">
                <Table
                    handleSearch={handleSearchParams}
                    isCheck={isCheck}
                    columns={columns}
                    handleSortBy={handleSortBy}
                    data={readingPlans}
                />
                </div>
                <Pagination
                    size={size}
                    totalElements={totalElements}
                    setSize={setSize}
                    page={page + 1}
                    changePage={pageChange}
                />
                {
                    iGroupDetails ?
                        <ReadingPlanModal
                            currentGroupId={iGroupDetails.id}
                            planId={currentPlan ? currentPlan.id : undefined}
                            setEditMode={setEditMode}
                            setShowCreateModal={setShowCreatePlanModal}
                            showCreateModal={showCreatePlanModal}
                            setAllList={setReadingPlans}
                            editMode={editMode}
                            page={page + 1}
                            size={size}
                            setTotalElements={setTotalElements}
                            createCall={createReadingPlan}
                            deleteCall={deleteReadingPlan}
                            updateCall={updateReadingPlan}
                            getAllCall={getAllPlansForIGroup}
                        />
                        :
                        <></>
                }
            </div>
        </>
    )
}
