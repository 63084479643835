import {Pagination, PaginationParams, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IBook, INameId} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllGenres = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<INameId>>('/api/dashboard/genres', {
        params,
    })

export const createGenre = (data: INameId) =>
    http.post<INameId>('/api/dashboard/genres', data)

export const updateGenre  = (id: UUID, data: INameId) =>
    http.put<INameId>(`/api/dashboard/genres/${id}`,  data )

export const deleteGenre  = (id: UUID) =>
    http.delete(`/api/dashboard/genres/${id}`, {})