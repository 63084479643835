import React from 'react';

type Props = {};

const BooksIndex = (props: Props) => {
  return (
    <div>BooksIndex</div>
  );
};

export default BooksIndex;