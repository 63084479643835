import {Pagination, PaginationParams, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IBook, INameId} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllCollections = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<INameId>>('/api/dashboard/collections', {
        params,
    })

export const createCollection = (data: INameId) =>
    http.post<INameId>('/api/dashboard/collections', data)

export const updateCollection  = (id: UUID, data: INameId) =>
    http.put<INameId>(`/api/dashboard/collections/${id}`,  data )

export const deleteCollection  = (id: UUID) =>
    http.delete(`/api/dashboard/collections/${id}`, {})