import {Pagination, PaginationParams, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IBook, INameId} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllLanguages = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<INameId>>('/api/dashboard/languages', {
        params,
    })

export const createLanguage = (data: INameId) =>
    http.post<INameId>('/api/dashboard/languages', data)

export const updateLanguage  = (id: UUID, data: INameId) =>
    http.put<INameId>(`/api/dashboard/languages/${id}`,  data )

export const deleteLanguage  = (id: UUID) =>
    http.delete(`/api/dashboard/languages/${id}`, {})