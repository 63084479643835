import {auth, http} from "../core/services/http";
import {LoginRequest, LogoutRequest} from "./model/LoginRequest";
import {LoginResponse} from "./model/LoginResponse";
import {ImpersonateRequest} from "./model/ImpersonateRequest";

export const login = (request : LoginRequest) =>
    auth.post<LoginResponse>('/login', request)

export const logout = (request : LogoutRequest) =>
    auth.post<LoginResponse>('/logout', request)

export const getUserId = (email: string) =>
    http.get<string>('/api/dashboard/users', {
        params: { email }
    })

export const impersonate = (request: ImpersonateRequest) =>
    http.post('/dashboard-config/control', request)

// export const getResetPasswordCode = (request : GetCodeResetPasswordRequest) =>
//     auth.put('/request-forgot-password', request)
//
// export const setResetPassword = (request : ResetPasswordRequest) =>
//     auth.put('/set-forgot-password', request)