import React from 'react';

type Props = {};

const HelpersIndex = (props: Props) => {
  return (
    <div>HelpersIndex</div>
  );
};

export default HelpersIndex;