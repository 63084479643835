import {Pagination, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IInstitutionGroup, IInstitutionGroupDetails} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllInstitutionGroups = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<IInstitutionGroup>>('/api/dashboard/institution-groups', {
        params,
    })

export const getAllInstitutionGroupsByInstitution = (id: string, params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<IInstitutionGroup>>(`/api/dashboard/institutions/${id}/groups`, {
        params,
    })

export const createInstitutionGroup = (id: string, data: IInstitutionGroup) =>
    http.post<IInstitutionGroup>('/api/dashboard/institution-groups', data)

export const getInstitutionGroup = (id: string) =>
    http.get<IInstitutionGroupDetails>(`/api/dashboard/institution-groups/${id}`, {})

export const updateInstitutionGroup = (id: UUID, data: IInstitutionGroup) =>
    http.put<IInstitutionGroup>(`/api/dashboard/institution-groups/${id}`, data)

export const deleteInstitutionGroup = (id: UUID) =>
    http.delete(`/api/dashboard/institution-groups/${id}`, {})