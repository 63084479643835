import {Pagination, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {
    IBookInPlanGroup,
    ICreateTemplatePlan,
    IReadingPlanTemplate,
    IReadingPlanTemplateLabel
} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllPlans = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<IReadingPlanTemplate>>('/api/dashboard/reading-plan-template', {
        params,
    })

export const createReadingPlanTemplate = (data: ICreateTemplatePlan) =>
    http.post<IReadingPlanTemplate>('/api/dashboard/reading-plan-template', data)

export const getPlan = (id: string) =>
    http.get<IReadingPlanTemplate>(`/api/dashboard/reading-plan-template/${id}`)

export const duplicateReadingPlan = ( templateId: string, request: IReadingPlanTemplateLabel) =>
    http.post<IReadingPlanTemplate>(`/api/dashboard/reading-plan-template/${templateId}/duplicate`, request)

export const renameReadingPlan = ( templateId: string, request: string) =>
    http.post<IReadingPlanTemplate>(`/api/dashboard/reading-plan-template/${templateId}/rename`, {
        label: request
    })

export const deleteReadingTemplatePlan = ( templateId: string) =>
    http.delete<IReadingPlanTemplate>(`/api/dashboard/reading-plan-template/${templateId}`)


export const getAvailableBooksInTemplate = (id: string) =>
    http.get<IBookInPlanGroup[]>(`/api/dashboard/reading-plan-template/${id}/available-books`)

export const addBookToGroup  = (bookId: string, groupId: string) =>
    http.post(`/api/dashboard/reading-plan-template/group/${groupId}/books/${bookId}`, {})

export const deleteBookFromGroup  = (bookId: string, groupId: string) =>
    http.delete(`/api/dashboard/reading-plan-template/group/${groupId}/books/${bookId}`, {})