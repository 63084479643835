import { map } from 'lodash-es'
import { FC, useRef, useState } from 'react'
import {
  Table as BTable,
  Button,
  Form,
  InputGroup,
  TableProps,
} from 'react-bootstrap'

export interface IColumn {
  title: string | JSX.Element
  accessor: string
  sortable?: boolean
  width?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (row: any, index: number) => JSX.Element | null | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: (row: any) => React.CSSProperties
  searchable?: boolean
}

interface ITable extends TableProps {
  columns: IColumn[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  isCheck?: string[]
  handleSortBy?: (column: string) => void
  columnWidth?: string
  handleSearch?: (column: IColumn, query: string) => void
  loading?: boolean
  noDataMessage?: string | JSX.Element
}

const NoDataComponent = ({
                           message,
                           colSpan,
                         }: {
  message?: string | JSX.Element
  colSpan?: number
}) => (
    <tr>
      <td colSpan={colSpan} className="text-center">
        {message || 'There is no data'}
      </td>
    </tr>
)

const ThElement = ({
                     column,
                     columnWidth,
                     handleSortBy,
                     handleSearch,
                   }: {
  column: IColumn
  columnWidth?: string
  handleSortBy?: (column: string) => void
  handleSearch?: (column: IColumn, query: string) => void
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState(false)
  const [query, setQuery] = useState('')
  const onChange = (val: string) => {
    setQuery(val)
    handleSearch?.(column, val)
  }
  return (
      <th
          style={{
            width: column.width || columnWidth || `12%`,
            cursor: column.sortable ? 'pointer' : 'default',
            whiteSpace: column.sortable ? 'nowrap' : 'normal',
          }}
      >
        {!search ? (
            <div
                className="d-flex w-100 justify-content-between align-items-center"
                style={{ height: 31 }}
                onClick={() =>
                    column.accessor ? handleSortBy?.(column.accessor) : null
                }
            >
              <span className="flex-grow-1">{column.title}</span>
              {column.searchable && (
                  <Button
                      size="sm"
                      variant="href"
                      onClick={(e) => {
                        e.stopPropagation()
                        setSearch(true)
                        setTimeout(() => {
                          ref.current?.focus()
                        })
                      }}
                  >
                    <i className="bi bi-search"></i>
                  </Button>
              )}
            </div>
        ) : (
            <div
                className="d-flex w-100 justify-content-between align-items-center"
                style={{
                  minWidth: '200px',
                }}
            >
              <InputGroup>
                <Form.Control
                    type="text"
                    value={query}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder="Search"
                    ref={ref}
                />
                <InputGroup.Text
                    onClick={(e) => {
                      e.stopPropagation()
                      onChange('')
                      setSearch(false)
                    }}
                >
                  <i className="bi bi-x-circle-fill"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
        )}
      </th>
  )
}

export const Table: FC<ITable> = ({
                                    columns,
                                    data,
                                    isCheck,
                                    handleSortBy,
                                    columnWidth,
                                    handleSearch,
                                    loading,
                                    noDataMessage,
                                  }) => {
  return (
      <BTable striped responsive>
        <thead>
        <tr>
          {map(columns, (column, index) => (
              <ThElement
                  columnWidth={columnWidth}
                  key={`head-${index}`}
                  handleSortBy={handleSortBy}
                  column={column}
                  handleSearch={handleSearch}
              />
          ))}
        </tr>
        </thead>
        <tbody>
        {map(data, (row, index) => (
            <tr key={`body-row-${index}`}>
              {map(columns, (column, tdIndex) => (
                  <td
                      key={`td-${index}-${tdIndex}`}
                      style={{
                        background: `${isCheck?.includes(row.id) ? '#E2D9F3' : ''}`,
                        ...column.style?.(row),
                      }}
                  >
                    {column.render
                        ? column.render(row, index)
                        : row[column.accessor]}
                  </td>
              ))}
            </tr>
        ))}
        {data.length === 0 && !loading && (
            <NoDataComponent message={noDataMessage} colSpan={columns.length} />
        )}
        </tbody>
      </BTable>
  )
}
