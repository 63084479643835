import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { DeleteConfirmationModal } from '../../core/components/DeleteConfirmationModal'
import { MessageBox } from '../../core/components/MessageBox'
import {IAddress, IInstitution, INameId} from "../../core/interfaces";
import {UUID} from "node:crypto";
import {Pagination, SimpleSearchPaginationParams} from "../../core/services/utils";
import axios, {AxiosResponse} from 'axios';

export interface InstitutionModalProps {
  showCreateModal: boolean
  setShowCreateModal: (show: boolean) => void
  setEditMode: (editMode: boolean) => void
  editMode: boolean
  page: number
  size: number
  setTotalElements: (elements: number) => void
  setAllList: (categories: IInstitution[]) => void
  institutionEntity?: IInstitution
  createCall: (data: IInstitution) => Promise<AxiosResponse<IInstitution>>
  deleteCall: (id: UUID) => Promise<AxiosResponse<any>>
  updateCall: (id: UUID, data: IInstitution) =>  Promise<AxiosResponse<IInstitution>>
  getAllCall: (params?: SimpleSearchPaginationParams) =>  Promise<AxiosResponse<Pagination<IInstitution>>>
}

export interface InstitutionModeledId {
  name?: string
  phone?: string
  email?: string
  readableIdPrefix?: string
  street?: string
  city?: string
  country?: string
}

export const InstitutionModal = (
  props: InstitutionModalProps,
): JSX.Element => {
  const {
    institutionEntity,
    setShowCreateModal,
    setAllList,
    showCreateModal,
    setEditMode,
    editMode,
    page,
    size,
    setTotalElements,
    createCall,
    deleteCall,
    updateCall,
    getAllCall

  } = props

  const [showDeleteConfirmationModal, setDeleteConfirmationModal] =
    useState<boolean>(false)

  const [isError, setIsError] = useState<boolean>(false)
  const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    if (editMode) {
      setValue('name', institutionEntity?.name)
      setValue('phone', institutionEntity?.phone)
      setValue('email', institutionEntity?.email)
      setValue('readableIdPrefix', institutionEntity?.readableIdPrefix)
      setValue('street', institutionEntity?.address?.street)
      setValue('city', institutionEntity?.address?.city)
      setValue('country', institutionEntity?.address?.country)
    }
  }, [institutionEntity, editMode])

  const schema = yup.object().shape({
    name: yup.string(),
    phone: yup.string(),
    email: yup.string(),
    readableIdPrefix: yup.string(),
    street: yup.string(),
    city: yup.string(),
    country: yup.string()

  })

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    reset,
    setValue,
  } = useForm<InstitutionModeledId>({
    mode: 'onChange',
    resolver: yupResolver<InstitutionModeledId>(schema),
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      readableIdPrefix: '',
      street:'',
      city:'',
      country:''
    },
  })

  const handleDelete = () => {
    // prettier-ignore
    (async () => {
      try {
        if (institutionEntity) {
          const { status } = await deleteCall(institutionEntity?.id)
          const response = await getAllCall({
            page: page - 1,
            size,
          })
          setTotalElements(response.data.totalElements)
          setAllList(response.data.content)
          if (status === 204 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Delete Successful')
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    })()
    handleClose()
  }

  const handleClose = () => {
    setShowCreateModal(false)
    setEditMode(false)
    reset()
  }

  const submit = (values: InstitutionModeledId) => {
    // prettier-ignore
    (async () => {
      try {
        const address: IAddress ={
          street: values.street,
          city: values.city,
          country: values.country
        }
        const data: IInstitution = {
          name: values.name,
          phone: values.phone,
          email: values.email,
          readableIdPrefix: values.readableIdPrefix,
          address: address,
        }
        if (institutionEntity) {
          const { status } = await updateCall(institutionEntity.id, data)
          const response = await getAllCall({
            page: page - 1,
            size,
          })
          setAllList(response.data.content)
          if (status === 200 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Update Successful')
          }
        } else {
          const { status } = await createCall(data)
          const response = await getAllCall({
            page: page - 1,
            size,
          })
          setTotalElements(response.data.totalElements)
          setAllList(response.data.content)
          if (status === 200 && response.status === 200) {
            setIsError(false)
            setShowMessageBox(true)
            setMessage('Create Successful')
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    })()
    handleClose()
  }

  return (
    <>
      <Modal
        show={showCreateModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode
              ? `Edit ${institutionEntity?.name}`
              : `Add New`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="iname-modal-form" onSubmit={handleSubmit(submit)}>
            <Form.Group className="d-flex flex-column align-items-start gap-3">
              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                <Form.Label
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Helvetica Neue',
                    fontWeight: '700',
                    lineHeight: '150%',
                    fontStyle: 'normal',
                    width: '132px',
                  }}
                  className="d-flex flex-column mb-0"
                >
                  Name
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors?.name?.message}
                  {...register('name')}
                  type="text"
                  placeholder="Enter Name"
                />

                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Readable ID Prefix
                </Form.Label>
                <Form.Control
                    disabled={editMode}
                    isInvalid={!!errors?.readableIdPrefix?.message}
                    {...register('readableIdPrefix')}
                    type="text"
                    placeholder="Enter Readable ID Prefix"
                />
              </Form.Group>
              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Phone
                </Form.Label>
                <Form.Control
                    isInvalid={!!errors?.phone?.message}
                    {...register('phone')}
                    type="text"
                    placeholder="Enter Phone"
                />

                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Email
                </Form.Label>
                <Form.Control
                    isInvalid={!!errors?.phone?.message}
                    {...register('email')}
                    type="text"
                    placeholder="Enter Email"
                />
              </Form.Group>
            </Form.Group>

            <Form.Group className="d-flex flex-column align-items-start gap-3">
              <span style={{
                fontSize: '32px',
                fontFamily: 'Helvetica Neue',
                fontWeight: '1200',
                lineHeight: '150%',
                fontStyle: 'normal',
                width: '132px',
              }}> Address </span>
              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Street
                </Form.Label>
                <Form.Control
                    isInvalid={!!errors?.street?.message}
                    {...register('street')}
                    type="text"
                    placeholder="Enter Street"
                />
              </Form.Group>
              <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                <Form.Label
                      style={{
                        fontSize: '16px',
                        fontFamily: 'Helvetica Neue',
                        fontWeight: '700',
                        lineHeight: '150%',
                        fontStyle: 'normal',
                        width: '132px',
                      }}
                      className="d-flex flex-column mb-0"
                  >
                    City
                  </Form.Label>
                  <Form.Control
                      isInvalid={!!errors?.city?.message}
                      {...register('city')}
                      type="text"
                      placeholder="Enter City"
                  />
                <Form.Label
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                      lineHeight: '150%',
                      fontStyle: 'normal',
                      width: '132px',
                    }}
                    className="d-flex flex-column mb-0"
                >
                  Country
                </Form.Label>
                <Form.Control
                    isInvalid={!!errors?.country?.message}
                    {...register('country')}
                    type="text"
                    placeholder="Enter Country"
                />
              </Form.Group>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {editMode && (
            <Button
              onClick={handleDelete}
              style={{ color: '#FFFFFF' }}
              className="btn btn-danger me-auto "
            >
              Delete
            </Button>
          )}
          <Button
            onClick={handleClose}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            form="iname-modal-form"
            type="submit"
            disabled={Object.keys(dirtyFields).length === 0}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteConfirmationModal
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        setDeleteConfirmationModal={setDeleteConfirmationModal}
        deleteTitle={'Delete'}
        deleteFunc={handleDelete}
      />
      <MessageBox
        message={message}
        setShowMessageBox={setShowMessageBox}
        showMessageBox={showMessageBox}
        isError={isError}
      />
    </>
  )
}
