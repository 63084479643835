'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useState} from "react";
import {IBookInstance} from "../../core/interfaces";
import {createBookInstances, deleteBookInstance, getAllBooksInstances, getBookCatalogPrice, updateBookInstance} from "../../services/books";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Button} from "@mui/material";
import {Pagination} from "../../core/components/Pagination";
import {Link, useParams} from "react-router-dom";
import {ArrowLeft} from "@mui/icons-material";
import {BookInstanceModal} from "./BookInstanceModal";

export const BookInstancesListPage: React.FC = (): JSX.Element => {
    const {
        page,
        setPage,
        size,
        setSize,
        totalElements,
        setTotalElements,
        sortBy,
        setSortBy,
    } = useTableParams({
        defaultSortBy: 'updatedAt',
    }
    )
    const {bookId} = useParams()
    const [catalogPrice, setCatalogPrice]= useState<number>(0)
    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [books, setBooks] = useState<IBookInstance[]>([])
    const [isCheck, setIsCheck] = useState<string[]>([])
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentBook, setCurrentBook] = useState<IBookInstance>()
    const [showCreateBookModal, setShowCreateBookModal] = useState<boolean>(false)


    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const response = await getAllBooksInstances(bookId, {
                page,
                size,
                sortBy,
            })
            setTotalElements(response.data.totalElements)
            setBooks(response.data.content)
            setPage(page + 1)

            const catalogPriceResponse = await getBookCatalogPrice(bookId)
            setCatalogPrice(catalogPriceResponse.data)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [page, size, sortBy])

    useEffect(() => {
        getData()
    }, [getData, page, size, sortBy])

    const handleSortBy = (sort: string) => {
        if (sort === sortBy) {
            setSortBy('updatedAt')
        } else {
            setSortBy(sort)
        }
    }

    const pageChange = async (newPage: number) => {
        setPage(newPage)
    }

    const columns: IColumn[] = [
        {
            title: 'uniqueReadableId',
            accessor: 'uniqueReadableId',
            sortable: true,
        },
        {
            title: 'institution',
            accessor: '',
            render: (row) => (
                <div>{row.institution.name}</div>
            ),
            sortable: true,
        },

        {
            title: 'status',
            accessor: 'status',
            sortable: true,
        },
        {
            title: 'rentCounter',
            accessor: 'rentCounter',
            sortable: true,
        },
        {
            title: 'catalogPrice',
            accessor: 'catalogPrice',
            sortable: true,
        },
        {
            title: 'boughtPrice',
            accessor: 'boughtPrice',
            sortable: true,
        },
        {
            title: 'Edit',
            accessor: '',
            render: (row) => (
                <div
                    style={{ cursor: 'pointer' }}
                    className="view"
                    onClick={() => {
                        setEditMode(true)
                        setCurrentBook(row)
                        setShowCreateBookModal(true)
                    }}
                >
                    Edit
                </div>
            ),
        }
    ]

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader />}

                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    <h2>
                        <Link to={`/books/list`}>
                            <ArrowLeft style={{backgroundColor: 'black', color: 'white', padding: '0px', width: '20px', height: '20px', textAlign: 'center', lineHeight: '1.1', borderRadius: '10px', marginRight: '10px'}}/>
                        </Link>
                        Book Instances</h2>

                    <div className="d-flex gap-2">
                        <Button variant="contained" size="small"onClick={() => {
                            setEditMode(false)
                            setShowCreateBookModal(true)
                            setCurrentBook(undefined)
                        }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="bi bi-arrow-right-square me-2 d-flex" />
                                <span>Add new Instance</span>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="overflow-x-auto">
                <Table
                    isCheck={isCheck}
                    columns={columns}
                    handleSortBy={handleSortBy}
                    data={books}
                />
                </div>
                <Pagination
                    size={size}
                    totalElements={totalElements}
                    setSize={setSize}
                    page={page + 1}
                    changePage={pageChange}
                />
                <BookInstanceModal
                    bookInstanceEntity={currentBook}
                    setEditMode={setEditMode}
                    setShowCreateModal={setShowCreateBookModal}
                    setAllList={setBooks}
                    showCreateModal={showCreateBookModal}
                    editMode={editMode}
                    page={page + 1}
                    size={size}
                    setTotalElements={setTotalElements}
                    createCall={createBookInstances}
                    deleteCall={deleteBookInstance}
                    updateCall={updateBookInstance}
                    getAllCall={getAllBooksInstances}
                    bookId={bookId}
                    catalogPrice={catalogPrice}
                />
            </div>
        </>
    )
}