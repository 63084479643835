'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {IAgeCategory, IBook, INameId} from "../../core/interfaces";
import {createBook, deleteBook, getAllBooks, updateBook} from "../../services/books";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Button} from "@mui/material";
import {AxiosResponse} from "axios";
import {Pagination} from "../../core/components/Pagination";
import {BookModal} from "./BookModal";
import {Link} from "react-router-dom";
import {BookFilterSearchPaginationParams, SimpleSearchPaginationParams} from "../../core/services/utils";
import {getAllPublishers} from "../../services/publishers";
import {Form} from "react-bootstrap";
import {map} from "lodash-es";
import {getAllInternalAgeGroups} from "../../services/internalAgeGroups";
import {getAllAuthorsWithData} from "../../services/authors";
import {getAllCategories} from "../../services/categories";
import {getAllAgeCategories} from "../../services/ageCategories";
import {getAllCollections} from "../../services/collections";
import {getAllIllustrators} from "../../services/illustrators";
import {getAllLanguages} from "../../services/langauges";
import {getAllTranslators} from "../../services/translators";

export const BookListPage: React.FC = (): JSX.Element => {
    const {
        page,
        setPage,
        size,
        setSize,
        totalElements,
        setTotalElements,
        sortBy,
        setSortBy,
    } = useTableParams({
        defaultSortBy: 'updatedAt',
    }
    )

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [books, setBooks] = useState<IBook[]>([])
    const [isCheck, setIsCheck] = useState<string[]>([])
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentBook, setCurrentBook] = useState<IBook>()
    const [showCreateBookModal, setShowCreateBookModal] = useState<boolean>(false)
    const searchParams = useRef<{ [key: string]: string }>({})
    const timer = useRef<NodeJS.Timeout | null>(null)
    const [allAgeGroups, setAllAgeGroups] = useState<INameId[]>([])
    const [filterInternalAgeGroup, setFilterInternalAgeGroup] = useState<string>('')

    const getData = useCallback(async () => {
        try {
            setLoading(true)
            var getAllParams: SimpleSearchPaginationParams = {
                page: 0,
                size: 1000
            };
            (async () => {
                const [allAgeGroups] = await Promise.all(
                    [getAllInternalAgeGroups(getAllParams)])
                setAllAgeGroups(allAgeGroups.data.content)
            })()

            const params: BookFilterSearchPaginationParams = {
                page,
                size,
                sortBy,
            };
            params.text = searchParams.current.name
            params.ageGroup = filterInternalAgeGroup;

            const response = await getAllBooks(params)
            setTotalElements(response.data.totalElements)
            setBooks(response.data.content)
            setPage(page + 1)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [page, size, sortBy, filterInternalAgeGroup])

    useEffect(() => {
        getData()
    }, [getData, page, size, sortBy, filterInternalAgeGroup])

    const handleSortBy = (sort: string) => {
        if (sort === sortBy) {
            setSortBy('updatedAt')
        } else {
            setSortBy(sort)
        }
    }

    const pageChange = async (newPage: number) => {
        setPage(newPage)
    }

    const handleSearchParams = useCallback(
        async (col: IColumn, q: string) => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            searchParams.current = {
                ...searchParams.current,
                [col.accessor]: q,
            }

            const newTimer = setTimeout(async () => {
                setLoading(true)
                try {
                    const params: SimpleSearchPaginationParams = {
                        page: 0,
                        size,
                        sortBy,
                    }

                    params.text = searchParams.current.name
                    const { data: res, status } = await getAllBooks(params)
                    if (status === 200) {
                        setBooks(res.content)
                        setTotalElements(res.totalElements)
                        setSize(res.size)
                        setPage(res.pageNumber + 1)
                    }
                } catch (err) {
                    console.log(err)
                } finally {
                    setLoading(false)
                }
            }, 2000)
            timer.current = newTimer
        },
        [page, size, sortBy],
    )

    const columns: IColumn[] = [
        {
            title: 'ISBN',
            accessor: 'isbn',
            sortable: true,
        },
        {
            title: 'Name',
            accessor: 'name',
            sortable: true,
            searchable: true
        },
        {
            title: 'Language',
            accessor: '',
            render: (row) => (
                <div className="d-flex flex-column">
                    <span>{row.language.name}</span>
                </div>
            ),
        },
        {
            title: 'Number of copies',
            accessor: 'stock',
            sortable: false,
        },
        {
            title: 'Copies currently rented',
            accessor: 'rented',
            sortable: false,
        },
        {
            title: 'Copies reserved',
            accessor: 'reserved',
            sortable: false,
        },
        {
            title: 'Edit',
            accessor: '',
            render: (row) => (
                <div
                    style={{ cursor: 'pointer' }}
                    className="view"
                    onClick={() => {
                        setEditMode(true)
                        setCurrentBook(row)
                        setShowCreateBookModal(true)
                    }}
                >
                    Edit
                </div>
            ),
        },
        {
            title: 'Instances',
            accessor: '',
            render: (row) => (

                <Link to={`/books/${row.id}/instances`}>
                    <Button className="btn btn-secondary">
                        Instances
                    </Button>
                </Link>
            ),
        }
    ]

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader />}
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    <h2>Books</h2>
                    <div className="d-flex gap-2">
                        <Button variant="contained" size="small"onClick={() => {
                            setEditMode(false)
                            setShowCreateBookModal(true)
                            setCurrentBook(undefined)
                        }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="bi bi-arrow-right-square me-2 d-flex" />
                                <span>Add new Book</span>
                            </div>
                        </Button>
                    </div>
                </div>
                <div    style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                }}>
                    Filters
                    <div className="d-flex gap-2">
                        <Form.Label
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Helvetica Neue',
                                fontWeight: '700',
                                lineHeight: '150%',
                                fontStyle: 'normal',
                                width: '132px',
                            }}
                            className="d-flex flex-column mb-0"
                        >
                            Internal Age Group
                        </Form.Label>
                        <Form.Select className="d-flex flex-column mb-0"

                                     onChange={(e) => {
                                         const val = e.target.value
                                         setFilterInternalAgeGroup(val);
                                     }}
                        >
                            <option disabled value={''}>
                                Select Internal Age Group
                            </option>
                            {map(allAgeGroups, (pub) => (
                                <option id={pub.id} key={pub.id} value={pub.id}>
                                    {pub.name}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <div className="overflow-x-auto">
                <Table
                    handleSearch={handleSearchParams}
                    isCheck={isCheck}
                    columns={columns}
                    handleSortBy={handleSortBy}
                    data={books}
                />
                </div>
                <Pagination
                    size={size}
                    totalElements={totalElements}
                    setSize={setSize}
                    page={page + 1}
                    changePage={pageChange}
                />
                <BookModal
                    bookEntity={currentBook}
                    setEditMode={setEditMode}
                    setShowCreateModal={setShowCreateBookModal}
                    setAllList={setBooks}
                    showCreateModal={showCreateBookModal}
                    editMode={editMode}
                    page={page + 1}
                    size={size}
                    setTotalElements={setTotalElements}
                    createCall={createBook}
                    deleteCall={deleteBook}
                    updateCall={updateBook}
                    getAllCall={getAllBooks}
                />
            </div>
        </>
    )
}