import {Pagination, PaginationParams, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IBook, INameId} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllPublishers = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<INameId>>('/api/dashboard/publishers', {
        params,
    })

export const createPublisher = (data: INameId) =>
    http.post<INameId>('/api/dashboard/publishers', data)

export const updatePublisher  = (id: UUID, data: INameId) =>
    http.put<INameId>(`/api/dashboard/publishers/${id}`,  data )

export const deletePublisher  = (id: UUID) =>
    http.delete(`/api/dashboard/publishers/${id}`, {})