import {useState} from "react";
import {Form} from "react-bootstrap";

interface EditableCellProps {
  value: string
  id: string
  updateCall: any

}

export const EditableCell = (props: EditableCellProps): JSX.Element => {

  const [editable, setEditable] = useState(false);
  const [internalValue, setInternalValue] = useState(props.value);

  const handleClose = () => {

  }

  function enableEdit() {
    setEditable(true);
  }

  function handleLoseFocus() {
    setEditable(false);
    props.updateCall(props.id, internalValue);
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalValue(e.target.value);
  }

  return (
    <div>
      {editable ?
          <Form.Control
              id="input-value-id"
              type="text"
              value={internalValue}
              onChange={onChange}
              onBlur={handleLoseFocus}
              contentEditable={true}
          />
          :
          <span onClick={enableEdit}>{internalValue}</span>
      }
    </div>
  )
}
