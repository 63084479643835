import Form from "react-bootstrap/Form";
import React, {ChangeEvent, FormEvent, useState} from 'react';
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";
import {Button, Col, Row} from "react-bootstrap";
import {LoginRequest} from "../../services/model/LoginRequest";
import {login} from "../../services/userService";
import {setAuthenticated, setRefreshToken, setToken} from "../../services/authStorageService";
type Props = {};

const HomePage = (props: Props) => {
  const [validated, setValidated] = useState(false);
  const [serverError, setServerError] = useState(false);
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState<LoginRequest>({
    email: '',
    password: ''
  });


  const handleSubmit = (event: FormEvent) => {
    const form = event.currentTarget;
    // @ts-ignore
    if (form.checkValidity() === false) {
    } else {
      login(credentials).then((response) => {
        setServerError(false);
        const token = response.data.access_token;
        const refreshToken = response.data.refresh_token;

        // Store the tokens in localStorage or secure cookie for later use
        setToken(token);
        setRefreshToken(refreshToken);
        setAuthenticated(true);
        // Redirect or perform other actions upon successful login
        navigate("/books");
        window.location.reload();
      }).catch(error => {
        console.error('Login Error :', error);
        // Display user-friendly error message
        setServerError(true);
      });
    }

    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };


  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  return (
      <>

        <div className="App">
          <div id="wave" className="parent top-section div-with-background">
            <div className="small-container align-left-mobile-only">
              <h1>Intră în cont</h1>
            </div>
          </div>

          <div className="parent top-section div-with-background">
            <div className="border align-left"
                 style={{marginTop: '10px', color: "black", fontSize: "initial", minWidth: "360px"}}>
              {serverError ? <div className="from-error">Eroare: parola pe care ai introdus-o este incorectă. Reîncearcă.</div> : <></>}
              <Form id="login-form" noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group  className="mb-3" style={{paddingTop: '5px'}}>
                  <Form.Label>
                    Email
                  </Form.Label>
                  <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      required
                  />
                  <Form.Control.Feedback type="invalid">
                    Te rugăm să introduci emailul.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group  className="mb-3" style={{paddingTop: '5px'}}>
                  <Form.Label>
                    Parolă
                  </Form.Label>
                  <Form.Control
                      type="password"
                      name="password"
                      placeholder="Parolă"
                      onChange={handleChange}
                      required
                  />
                  <Form.Control.Feedback type="invalid">
                    Te rugăm să introduci parola.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group  className="mb-3">
                  <Row className="g-2">
                    <Col xs >
                      <Button type="submit" className="button button-contact">
                        Login
                      </Button>
                    </Col>
                    <Col xs  style={{textAlign: 'left', marginLeft: '-120px'}}>
                      <Link to={"/reset-password"}>
                        <div className="button">
                          <span>Mi-am uitat parola</span>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </Form.Group>

                <Typography id="modal-modal-title" variant="h6" component="h2"
                            className="fauna-one-regular header span-minor-text"
                            style={{textAlign: 'center'}}>
                  Nu ai cont încă?<br/> <strong><Link to="/register">Înregistrează-te aici</Link></strong>
                </Typography>
              </Form>
            </div>
          </div>
        </div>
        <div>
        </div>
        <div className="gradient-wave-bg"></div>
        </>
  );
};

export default HomePage;