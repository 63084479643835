'use client';
import React, {useCallback, useEffect, useState} from "react";
import {IBookInPlanGroup, IReadingPlanTemplate} from "../../core/interfaces";
import Loader from "../../core/components/Loader";
import {Link, useParams} from "react-router-dom";
import {ArrowLeft} from "@mui/icons-material";
import {getAvailableBooksInTemplate} from "../../services/templatePlans";
import {PlanGroupDetails} from "../books/PlanGroupDetails";
import {getAvailableBooksInPlan, getReadingPlan} from "../../services/readingPlans";

export const ReadingPlanGroupsListPage: React.FC = (): JSX.Element => {

    const {iGroupId, planId} = useParams()

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [plan, setPlan] = useState<IReadingPlanTemplate>()
    const [avBooks, setAvBooks] = useState<IBookInPlanGroup[]>([])

    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const response = await getReadingPlan(planId)
            setPlan(response.data)

            const [books] = await Promise.all(
                [getAvailableBooksInPlan(planId)])
            setAvBooks(books.data)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    },[])

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            {loading && <Loader />}

            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >

                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    <h2>
                        <Link to={`/plans/institution-group/${iGroupId}`}>
                            <ArrowLeft style={{backgroundColor: 'black', color: 'white', padding: '0px', width: '20px', height: '20px', textAlign: 'center', lineHeight: '1.1', borderRadius: '10px', marginRight: '10px'}}/>
                        </Link>
                        Template Plan {plan?.label}</h2>

                </div>
                <div className="overflow-x-auto">

                    {plan?.readingPlanTemplateGroups.map(planGroup => <PlanGroupDetails group={planGroup} avBooks={avBooks} getData={getData}/>)}
                </div>
            </div>

        </>
    )
}