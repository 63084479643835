export const getToken = () => {
    return localStorage.getItem('token');
}

export const removeToken = () => {
    return localStorage.removeItem('token');
}

export const setToken = (token : string) => {
    return localStorage.setItem('token', token);
}

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
}

export const removeRefreshToken = () => {
    return localStorage.removeItem('refreshToken');
}

export const setRefreshToken = (token : string) => {
    return localStorage.setItem('refreshToken', token);
}

export const isAuthenticated = () => {
    return localStorage.getItem('authenticated') !== undefined && localStorage.getItem('authenticated') === "true";
}

export const setAuthenticated = (authenticated : boolean) => {
    return localStorage.setItem('authenticated', authenticated ? 'true' : 'false');
}

