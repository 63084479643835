import { PaginationControl } from 'react-bootstrap-pagination-control'
import Select, { components } from 'react-select'

export interface PaginationProps {
  page: number
  size: number
  setSize: (size: number) => void
  totalElements: number
  changePage: (page: number) => void
}

export const Pagination = (props: PaginationProps): JSX.Element => {
  const { page, size, setSize, totalElements, changePage } = props

  const selectSuffix = ' / page'

  const options = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 40, label: 40 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ]

  const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#FFF' : '#000',
      backgroundColor: state.isSelected ? '#6f42c1' : '#FFF',
      '&:hover': {
        backgroundColor: '#6f42c1',
        color: '#FFF',
        opacity: '0.5',
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#FFF',
      boxShadow: state.isFocused ? '0 0 0 1px #6f42c1' : undefined,
      borderColor: state.isFocused ? '#6f42c1' : defaultStyles.borderColor,
      '&:hover': {
        borderColor: state.isFocused ? '#6f42c1' : defaultStyles.borderColor,
      },
    }),
  }

  return (
    <div className="d-flex justify-content-center align-items-center gap-3">
      <h6 style={{ marginBottom: '16px' }}>Total {totalElements} items</h6>
      <PaginationControl
        page={page}
        between={3}
        total={totalElements}
        limit={size}
        changePage={changePage}
        ellipsis={1}
      />

      <div style={{ width: '135px', marginBottom: '16px' }}>
        <Select
          options={options}
          styles={customStyles}
          defaultValue={{ value: size, label: size }}
          isClearable={false}
          onChange={(e) => setSize(e?.value || 1)}
          placeholder="10"
          menuPlacement="top"
          components={{
            SingleValue: ({ children, ...props }) => {
              return (
                <components.SingleValue {...props}>
                  {children + selectSuffix}
                </components.SingleValue>
              )
            },
            Placeholder: ({ children, ...props }) => {
              return (
                <components.Placeholder {...props}>
                  {children + selectSuffix}
                </components.Placeholder>
              )
            },
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div
        className="d-flex gap-2 align-items-center"
        style={{ marginBottom: '16px' }}
      >
        Go to
        <input
          className="form-control bg-white"
          type="number"
          min={1}
          max={Math.ceil(totalElements / size)}
          onKeyUp={(value) => {
            if (value.currentTarget.value != '') {
              if (
                parseInt(value.currentTarget.value) <
                parseInt(value.currentTarget.min)
              ) {
                value.currentTarget.value = value.currentTarget.min
              }
              if (
                parseInt(value.currentTarget.value) >
                parseInt(value.currentTarget.max)
              ) {
                value.currentTarget.value = value.currentTarget.max
              }
            }
          }}
          style={{ borderRadius: '4px', width: '50px' }}
          onChange={(e) => {
            if (e.target.value !== '') {
              if (parseInt(e.target.value) > Math.ceil(totalElements / size)) {
                changePage(Math.ceil(totalElements / size))
              } else {
                changePage(parseInt(e.target.value))
              }
            }
          }}
        />
      </div>
    </div>
  )
}
