'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {IInstitutionGroup, INameId} from "../../core/interfaces";
import {
    createInstitutionGroup,
    deleteInstitutionGroup,
    getAllInstitutionGroups, getAllInstitutionGroupsByInstitution,
    updateInstitutionGroup
} from "../../services/institutionGroups";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Pagination} from "../../core/components/Pagination";
import {Button} from "@mui/material";
import {SimpleSearchPaginationParams} from "../../core/services/utils";
import {INameModalEnhanced} from "./INameModalEnhanced";
import {Link, useParams} from "react-router-dom";
import {ArrowLeft} from "@mui/icons-material";

export const InstitutionGroupsListPage: React.FC = (): JSX.Element => {
    const {
        page,
        setPage,
        size,
        setSize,
        totalElements,
        setTotalElements,
        sortBy,
        setSortBy,

    } = useTableParams({
        defaultSortBy: 'updatedAt',
    }
    )
    const {instId} = useParams()
    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [institutionGroups, setInstitutionGroups] = useState<IInstitutionGroup[]>([])
    const [isCheck, setIsCheck] = useState<string[]>([])
    const searchParams = useRef<{ [key: string]: string }>({})
    const timer = useRef<NodeJS.Timeout | null>(null)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentInstitutionGroup, setCurrentInstitutionGroup] = useState<IInstitutionGroup>()
    const [showCreateInstitutionGroupModal, setShowCreateInstitutionGroupModal] = useState<boolean>(false)
    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const params: SimpleSearchPaginationParams = {
                page,
                size,
                sortBy,
            };
            params.text = searchParams.current.name
            const response = await getAllInstitutionGroupsByInstitution(instId, params)
            setTotalElements(response.data.totalElements)
            setInstitutionGroups(response.data.content)
            setPage(page + 1)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [page, size, sortBy])

    useEffect(() => {
        getData()
    }, [getData, page, size, sortBy])

    const handleSortBy = (sort: string) => {
        if (sort === sortBy) {
            setSortBy('updatedAt')
        } else {
            setSortBy(sort)
        }
    }

    const pageChange = async (newPage: number) => {
        setPage(newPage)
    }

    const handleSearchParams = useCallback(
        async (col: IColumn, q: string) => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            searchParams.current = {
                ...searchParams.current,
                [col.accessor]: q,
            }

            const newTimer = setTimeout(async () => {
                setLoading(true)
                try {
                    const params: SimpleSearchPaginationParams = {
                        page: 0,
                        size,
                        sortBy,
                    }

                    params.text = searchParams.current.name
                    const { data: res, status } = await getAllInstitutionGroupsByInstitution(instId, params)
                    if (status === 200) {
                        setInstitutionGroups(res.content)
                        setTotalElements(res.totalElements)
                        setSize(res.size)
                        setPage(res.pageNumber + 1)
                    }
                } catch (err) {
                    console.log(err)
                } finally {
                    setLoading(false)
                }
            }, 2000)
            timer.current = newTimer
        },
        [page, size, sortBy],
    )

    const columns: IColumn[] = [
        {
            title: 'ID',
            accessor: 'id',
            sortable: true,
        },
        {
            title: 'Name',
            accessor: 'name',
            sortable: true,
            searchable: true
        },
        {
            title: 'Edit',
            accessor: '',
            render: (row) => (
                <div
                    style={{ cursor: 'pointer' }}
                    className="view"
                    onClick={() => {
                        setEditMode(true)
                        setCurrentInstitutionGroup(row)
                        setShowCreateInstitutionGroupModal(true)
                    }}
                >
                    Edit
                </div>
            ),
        },
        {
            title: 'Plans',
            accessor: '',
            render: (row) => (
                <Link to={`/plans/institution-group/${row.id}`}>
                    <div  style={{ cursor: 'pointer' }} className="view">
                        Plans
                    </div>
                </Link>
            ),
        }
    ]

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader />}
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    <h2>
                        <Link to={`/institutions/list`}>
                            <ArrowLeft style={{backgroundColor: 'black', color: 'white', padding: '0px', width: '20px', height: '20px', textAlign: 'center', lineHeight: '1.1', borderRadius: '10px', marginRight: '10px'}}/>
                        </Link>
                        InstitutionGroups</h2>
                    <div className="d-flex gap-2">
                        <Button variant="contained" size="small" onClick={() => {
                            setEditMode(false)
                            setShowCreateInstitutionGroupModal(true)
                            setCurrentInstitutionGroup(undefined)
                        }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="bi bi-arrow-right-square me-2 d-flex" />
                                <span>Add new InstitutionGroup</span>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="overflow-x-auto">
                <Table
                    handleSearch={handleSearchParams}
                    isCheck={isCheck}
                    columns={columns}
                    handleSortBy={handleSortBy}
                    data={institutionGroups}
                />
                </div>
                <Pagination
                    size={size}
                    totalElements={totalElements}
                    setSize={setSize}
                    page={page + 1}
                    changePage={pageChange}
                />
                <INameModalEnhanced
                    instId={instId}
                    iNameEntity={currentInstitutionGroup}
                    setEditMode={setEditMode}
                    setShowCreateModal={setShowCreateInstitutionGroupModal}
                    setAllList={setInstitutionGroups}
                    showCreateModal={showCreateInstitutionGroupModal}
                    editMode={editMode}
                    page={page + 1}
                    size={size}
                    setTotalElements={setTotalElements}
                    createCall={createInstitutionGroup}
                    deleteCall={deleteInstitutionGroup}
                    updateCall={updateInstitutionGroup}
                    getAllCall={getAllInstitutionGroups}
                />

            </div>
        </>
    )
}