'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {IBook, INameId, IReadingPlanTemplate} from "../../core/interfaces";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Button} from "@mui/material";
import {Pagination} from "../../core/components/Pagination";
import {Link} from "react-router-dom";
import {SimpleSearchPaginationParams} from "../../core/services/utils";
import {
    createReadingPlanTemplate,
    deleteReadingTemplatePlan,
    duplicateReadingPlan,
    getAllPlans, renameReadingPlan
} from "../../services/templatePlans";
import {PlanTemplateModal} from "./PlanTemplateModal";
import {EditableCell} from "../../core/components/EditableCell";

export const PlansListPage: React.FC = (): JSX.Element => {
    const {
        page,
        setPage,
        size,
        setSize,
        totalElements,
        setTotalElements,
        sortBy,
        setSortBy,
    } = useTableParams({
        defaultSortBy: 'updatedAt',
    }
    )

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [plans, setPlans] = useState<IReadingPlanTemplate[]>([])
    const [isCheck, setIsCheck] = useState<string[]>([])
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentBook, setCurrentBook] = useState<IBook>()
    const [showCreateBookModal, setShowCreateBookModal] = useState<boolean>(false)
    const searchParams = useRef<{ [key: string]: string }>({})
    const timer = useRef<NodeJS.Timeout | null>(null)
    const [allAgeGroups, setAllAgeGroups] = useState<INameId[]>([])
    const [filterInternalAgeGroup, setFilterInternalAgeGroup] = useState<string>('')

    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const params: SimpleSearchPaginationParams = {
                page,
                size,
                sortBy,
            };
            const response = await getAllPlans(params)
            setTotalElements(response.data.totalElements)
            setPlans(response.data.content)
            setPage(page + 1)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [page, size, sortBy, filterInternalAgeGroup])

    useEffect(() => {
        getData()
    }, [getData, page, size, sortBy, filterInternalAgeGroup])

    const handleSortBy = (sort: string) => {
        if (sort === sortBy) {
            setSortBy('updatedAt')
        } else {
            setSortBy(sort)
        }
    }

    const pageChange = async (newPage: number) => {
        setPage(newPage)
    }

    const duplicate = (id: string, label: string) =>  {
        console.log(id);
        console.log(label);

        duplicateReadingPlan(id, {
            label: label + "_DUPLICATED"
        }).then(() => getData());

        return undefined;
    }

    const deleteCall = (id:string) => {
        deleteReadingTemplatePlan(id).then(() => getData());
        return undefined;
    }


    const columns: IColumn[] = [
        {
            title: 'Label',
            accessor: '',
            sortable: true,
            searchable: true,
            render: (row) => (
                <EditableCell value={row.label} id= {row.id} updateCall={renameReadingPlan}></EditableCell>
            )
        },
        {
            title: 'Groups',
            accessor: 'groups',
            sortable: true,
            searchable: true
        },
        {
            title: 'Books in group',
            accessor: 'booksInGroup',
            sortable: true,
            searchable: true
        },
        {
            title: 'Internal Age Group',
            accessor: '',
            render: (row) => (
                <div className="d-flex flex-column">
                    <span>{row.internalAgeGroup.name}</span>
                </div>
            ),
        },
        {
            title: 'Groups',
            accessor: '',
            render: (row) => (

                <Link to={`/books/plans/${row.id}/groups`}>
                    <Button className="btn btn-secondary">
                        Groups
                    </Button>
                </Link>
            ),
        },
        {
            title: 'Duplicate',
            accessor: '',
            render: (row) => (
                <Button className="btn btn-secondary" onClick={(e) => duplicate(row.id, row.label)}>
                    Duplicate
                </Button>
            ),
        },
        {
            title: 'Delete',
            accessor: '',
            render: (row) => (
                <Button className="btn btn-secondary" onDoubleClick={(e) => deleteCall(row.id)}>
                    Delete
                </Button>
            ),
        }
    ]

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader />}
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    <h2>Template plans</h2>
                    <div className="d-flex gap-2">
                        <Button variant="contained" size="small"onClick={() => {
                            setEditMode(false)
                            setShowCreateBookModal(true)
                            setCurrentBook(undefined)
                        }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="bi bi-arrow-right-square me-2 d-flex" />
                                <span>Add new Template</span>
                            </div>
                        </Button>
                    </div>
                </div>

                <div className="overflow-x-auto">
                <Table
                    // handleSearch={handleSearchParams}
                    isCheck={isCheck}
                    columns={columns}
                    handleSortBy={handleSortBy}
                    data={plans}
                />
                </div>
                <Pagination
                    size={size}
                    totalElements={totalElements}
                    setSize={setSize}
                    page={page + 1}
                    changePage={pageChange}
                />
                <PlanTemplateModal
                    setEditMode={setEditMode}
                    setShowCreateModal={setShowCreateBookModal}
                    setAllList={setPlans}
                    showCreateModal={showCreateBookModal}
                    editMode={editMode}
                    page={page + 1}
                    size={size}
                    setTotalElements={setTotalElements}
                    createCall={createReadingPlanTemplate}
                    getAllCall={getAllPlans}
                />
            </div>
        </>
    )
}