import {yupResolver} from '@hookform/resolvers/yup'
import {ChangeEvent, useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import * as yup from 'yup'

import {DeleteConfirmationModal} from '../../core/components/DeleteConfirmationModal'
import {MessageBox} from '../../core/components/MessageBox'
import {
    IAuthor,
    IBook,
    IBookInstance,
    ICreateTemplatePlan,
    Image,
    INameId,
    IPhoto,
    IReadingPlanTemplate
} from "../../core/interfaces";
import {UUID} from "node:crypto";
import {Pagination, SimpleSearchPaginationParams} from "../../core/services/utils";
import {AxiosResponse} from 'axios';
import {getAllAuthorsWithData} from "../../services/authors";
import {getAllAgeCategories} from "../../services/ageCategories";
import {getAllCollections} from "../../services/collections";
import {getAllCategories} from "../../services/categories";
import {getAllIllustrators} from "../../services/illustrators";
import {getAllLanguages} from "../../services/langauges";
import {getAllPublishers} from "../../services/publishers";
import {map} from "lodash-es";
import Select from "react-select";
import {getAllTranslators} from "../../services/translators";
import {FormUploadPreview} from "../../core/components/FormUploadPreview";
import {uploadImage} from "../../services/upload-image";
import {getAllInternalAgeGroups} from "../../services/internalAgeGroups";
import {getAllGenres} from "../../services/genres";


export interface PlanTemplateModalProps {
    showCreateModal: boolean
    setShowCreateModal: (show: boolean) => void
    setEditMode: (editMode: boolean) => void
    editMode: boolean
    page: number
    size: number
    setTotalElements: (elements: number) => void
    setAllList: (categories: IReadingPlanTemplate[]) => void
    createCall: (data: ICreateTemplatePlan) => Promise<AxiosResponse<IReadingPlanTemplate>>
    getAllCall: (params?: SimpleSearchPaginationParams) => Promise<AxiosResponse<Pagination<IReadingPlanTemplate>>>
}


export interface CreatePlanTemplateModeled {
    groupsInTemplate?:number
    booksInGroup?:number
    booksInBackupGroup?:number
    autoPopulate?: boolean
    label?:string
    internalAgeGroupId?: string
}


export const PlanTemplateModal = (
    props: PlanTemplateModalProps,
): JSX.Element => {
    const {
        setShowCreateModal,
        setAllList,
        showCreateModal,
        setEditMode,
        editMode,
        page,
        size,
        setTotalElements,
        createCall,
        getAllCall

    } = props

    const [showDeleteConfirmationModal, setDeleteConfirmationModal] =
        useState<boolean>(false)

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [allAgeGroups, setAllAgeGroups] = useState<INameId[]>([])


    useEffect(() => {
        var getAllParams: SimpleSearchPaginationParams = {
            page: 0,
            size: 10000
        };
        (async () => {
            const [allAgeGroups] = await Promise.all(
                [getAllInternalAgeGroups(getAllParams)])

            setAllAgeGroups(allAgeGroups.data.content)
        })()
    }, [])

    useEffect(() => {


    }, [editMode])

    const schema = yup.object().shape({

        groupsInTemplate:yup.number().min(1),
        booksInGroup:yup.number().min(1),
        booksInBackupGroup:yup.number().min(0),
        autoPopulate: yup.boolean(),
        label:yup.string(),
        internalAgeGroupId: yup.string()
    })

    const {
        handleSubmit,
        register,
        formState: {errors, dirtyFields},
        reset,
        setValue,
    } = useForm<CreatePlanTemplateModeled>({
        mode: 'onChange',
        resolver: yupResolver<CreatePlanTemplateModeled>(schema),
        defaultValues: {
            groupsInTemplate:0,
            booksInGroup:0,
            booksInBackupGroup:0,
            autoPopulate: false,
            label: '',
            internalAgeGroupId: ''
        },
    })

    const handleDelete = () => {
        // prettier-ignore
        handleClose()
    }

    const handleClose = () => {
        setShowCreateModal(false)
        setEditMode(false)
        reset()
    }

    const submit = (values: CreatePlanTemplateModeled) => {
        // prettier-ignore
        (async () => {
            try {


                const data: ICreateTemplatePlan = {
                    groups: values.groupsInTemplate,
                    booksInGroup: values.booksInGroup,
                    booksInBackupGroup: values.booksInBackupGroup,
                    autoPopulate: values.autoPopulate,
                    label: values.label,
                    internalAgeGroupId: values.internalAgeGroupId
                }

                const {status} = await createCall(data)
                const response = await getAllCall({
                    page: page - 1,
                    size,
                })
                setTotalElements(response.data.totalElements)
                setAllList(response.data.content)
                if (status === 200 && response.status === 200) {
                    setIsError(false)
                    setShowMessageBox(true)
                    setMessage('Create Successful')
                }
            } catch (e) {
                if (e instanceof Error) {
                    setIsError(true)
                    setShowMessageBox(true)
                    setMessage(e.message)
                } else {
                    console.error(e)
                }
            }
        })()
        handleClose()
    }

    return (
        <>
            <Modal
                show={showCreateModal}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="template-plan-form" onSubmit={handleSubmit(submit)}>
                        <Form.Group className="d-flex flex-column align-items-start gap-3">
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Label
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.label?.message}
                                    {...register('label')}
                                    type="text"
                                    placeholder="Enter Label"
                                />
                            </Form.Group>

                            <Form.Group
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Internal Age Group
                                    </Form.Label>
                                    <Form.Select className="d-flex flex-column mb-0"
                                                 defaultValue={''}
                                                 isInvalid={!!errors?.internalAgeGroupId?.message}
                                                 onChange={(e) => {
                                                     const val = e.target.value
                                                     setValue('internalAgeGroupId', val, {shouldDirty: true})
                                                 }}
                                    >
                                        <option disabled value={''}>
                                            Select Internal Age Group
                                        </option>
                                        {map(allAgeGroups, (ageGroup) => (
                                            <option id={ageGroup.id} key={ageGroup.id} value={ageGroup.id}>
                                                {ageGroup.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Form.Group>
                            <Form.Group content="groups"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Number of groups
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={!!errors?.groupsInTemplate?.message}
                                        {...register('groupsInTemplate')}
                                        type="number"
                                        placeholder="Enter number of groups"
                                    />

                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Nr. Books in Group
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={!!errors?.booksInGroup?.message}
                                        {...register('booksInGroup')}
                                        type="number"
                                        placeholder="Enter number books in group"
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center gap-3 align-left">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Nr. Books in Backup Group
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.booksInBackupGroup?.message}
                                    {...register('booksInBackupGroup')}
                                    type="number"
                                    placeholder="Enter number books in backup"
                                />
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Auto populate with books?
                                </Form.Label>
                                <input
                                    type="checkbox"
                                    {...register('autoPopulate')}
                                />
                            </Form.Group>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleClose}
                        className="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-primary"
                        form="template-plan-form"
                        type="submit"
                        disabled={Object.keys(dirtyFields).length === 0}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <MessageBox
                message={message}
                setShowMessageBox={setShowMessageBox}
                showMessageBox={showMessageBox}
                isError={isError}
            />
        </>
    )
}
