import {Pagination, PaginationParams, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IBook, INameId} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllTranslators = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<INameId>>('/api/dashboard/translators', {
        params,
    })

export const createTranslator = (data: INameId) =>
    http.post<INameId>('/api/dashboard/translators', data)

export const updateTranslator  = (id: UUID, data: INameId) =>
    http.put<INameId>(`/api/dashboard/translators/${id}`,  data )

export const deleteTranslator  = (id: UUID) =>
    http.delete(`/api/dashboard/translators/${id}`, {})