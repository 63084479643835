import {IBook, IBookInPlanGroup, IReadingPlanTemplateGroup} from "../../core/interfaces";
import {display} from "@mui/system";
import {Button, Form} from "react-bootstrap";
import {UUID} from "node:crypto";
import {addBookToGroup, deleteBookFromGroup, getAvailableBooksInTemplate} from "../../services/templatePlans";
import {useEffect, useState} from "react";
import {getAllAuthorsWithData} from "../../services/authors";
import {getAllCategories} from "../../services/categories";
import {getAllAgeCategories} from "../../services/ageCategories";
import {getAllCollections} from "../../services/collections";
import {getAllIllustrators} from "../../services/illustrators";
import {getAllLanguages} from "../../services/langauges";
import {getAllPublishers} from "../../services/publishers";
import {getAllTranslators} from "../../services/translators";
import {getAllInternalAgeGroups} from "../../services/internalAgeGroups";
import {map} from "lodash-es";

export interface PlanGroupProps {
    group?: IReadingPlanTemplateGroup
    avBooks?: IBookInPlanGroup[]
    getData?: () => Promise<void>
}
export const PlanGroupDetails = (
    props: PlanGroupProps
): JSX.Element => {
    const {
        group,
        avBooks,
        getData
    } = props
    const [refresh, setRefresh] = useState<boolean>(false)

    async function refreshAvBooks() {
        const [books] = await Promise.all(
            [getAvailableBooksInTemplate(group.readingPlanTemplateId)])
        avBooks.splice(0, avBooks.length);
        books.data.forEach(book => avBooks.push(book))
    }

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, bookId: string, groupId: string): void => {
        deleteBookFromGroup(bookId, groupId).then(value => {
            var book = group.books.find(value => value.id == bookId);
            var bookIndex = group.books.findIndex(value => value.id == bookId);
            group.books[bookIndex] = {
                id: null,
                name: null,
                photos: null,
                catalogPrice: null,
                boughtPrice: null,
                hasQuestionsAndActivities: null
            }
            refreshAvBooks().then(() => setRefresh(!refresh));
        })
        e.preventDefault();

        // window.location.reload();
    }

    const handleAdd = (bookId: string, groupId: string): void => {
        addBookToGroup(bookId, groupId).then(() => {
                refreshAvBooks().then(() => setRefresh(!refresh));
        })
    }
    useEffect(() => {
        getData()
    }, [refresh]);
    return (
        <>
            <div style={{
                display: "inline-flex",
                backgroundColor: group?.color?.code,
                borderRadius: "20px",
                margin: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                flexWrap: "wrap",
                flexDirection: "row"
            }}>
                {group?.books.map(book =>
                    book?.name !== null ?
                        <div style={{
                            justifyContent: "center",
                            flex: "1",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            minWidth: "250px"
                        }} className={"xHover"}>
                            <Button
                                onClick={(e) => handleDelete(e, book.id, group.id)}
                                className="btn btn-close hidden"
                                type="button"
                                style={{color: '#FFF', fontSize: "xxx-large", position: "absolute", zIndex: 999}}
                            >
                            </Button>
                            <div style={{minWidth: "20%"}}>
                                <span>BOOK PRICE {book.catalogPrice} vs {book.boughtPrice}</span></div>
                            <div style={{minWidth: "20%"}}>
                                {book.hasQuestionsAndActivities ?
                                    <span style={{color: 'green'}}>HAS QUESTIONS AND ACTIVITIES</span> :
                                    <span style={{color: 'red'}}>DOES NOT HAVE QUESTIONS</span>}
                            </div>
                            <div style={{minWidth: "20%"}}><span>{book.name}</span></div>
                            <div style={{margin: "auto"}}><img src={book?.photos[0]?.url}
                                                               style={{maxWidth: "250px", maxHeight: "250px"}}/></div>

                        </div>
                        :
                        <div style={{
                            justifyContent: "center",
                            flex: "1",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            minWidth: "20%"
                        }}>
                            <div style={{}}><span>PlaceHolder</span></div>
                            <div style={{margin: "auto", minHeight: "250px"}}>
                                <Form.Select className="d-flex flex-column mb-0"
                                             defaultValue={'Select book'}
                                             onChange={(e) => {
                                                 const val = e.target.value
                                                 handleAdd(val, group.id)
                                             }}
                                >
                                    <option value={''} key={''}>
                                        Select Book
                                    </option>
                                    {map(avBooks, (book) => (
                                        <option id={book.id} key={book.id} value={book.id}>
                                            {book.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                )}
                <div style={{minWidth: "20%"}}><span>GROUP PRICE {group?.catalogPrice} vs {group?.boughtPrice}</span>
                </div>

            </div>
        </>
    );
}