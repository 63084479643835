import {http} from "../core/services/http";

export const uploadImages = async (file: File) => {
    const formData = new FormData()
    formData.append('photo', file)
    return http.post<{
        photoKey: string
        photoUrl: string
    }>(`/api/dashboard/photo/upload`, formData)
}

export const uploadImage = async (
    file: File,
    setIsUploading: (u: boolean) => void,
    setIsError: (e: boolean) => void,
    setShowMessageBox: (s: boolean) => void,
    setMessage: (s: string) => void,
) => {
    setIsUploading(true)
    try {
        const {data} = await uploadImages(file)
        return {photoKey: data.photoKey, photoUrl: data.photoUrl}
    } catch (e) {
        if (e instanceof Error) {
            setIsError(true)
            setShowMessageBox(true)
            setMessage(e.message)
        } else {
            console.error(e)
        }
        return {photoKey: '', photoUrl: ''}
    } finally {
        setIsUploading(false)
    }
}
