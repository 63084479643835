import {Pagination, PaginationParams, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IAgeCategory, IBook, INameId} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllAgeCategories = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<IAgeCategory>>('/api/dashboard/age-categories', {
        params,
    })

export const createAgeCategory = (data: IAgeCategory) =>
    http.post<IAgeCategory>('/api/dashboard/age-categories', data)

export const updateAgeCategory  = (id: UUID, data: IAgeCategory) =>
    http.put<IAgeCategory>(`/api/dashboard/age-categories/${id}`,  data )

export const deleteAgeCategory  = (id: UUID) =>
    http.delete(`/api/dashboard/age-categories/${id}`, {})